import React from 'react';

import { withTheme } from 'styled-components';

import SVG from './SVG';

const RefreshIcon = (props) => (
  <SVG SVG width="1rem" height="1rem" viewBox="0 0 16 16" {...props}>
    <g>
      <path
        id="Vector"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.60008 4.85554C4.52717 3.53355 6.06056 2.66667 7.80008 2.66667C10.6354 2.66667 12.9334 4.96472 12.9334 7.8C12.9334 8.05774 12.7245 8.26667 12.4667 8.26667C12.209 8.26667 12.0001 8.05774 12.0001 7.8C12.0001 5.48018 10.1199 3.60001 7.80008 3.60001C6.37997 3.60001 5.1256 4.30678 4.36588 5.3889H5.07786C5.33559 5.3889 5.54453 5.59783 5.54453 5.85556C5.54453 6.11329 5.33559 6.32223 5.07786 6.32223H3.57162C3.56483 6.32238 3.55803 6.32238 3.55121 6.32223H3.13341C2.87568 6.32223 2.66675 6.11329 2.66675 5.85556V3.91112C2.66675 3.65338 2.87568 3.44445 3.13341 3.44445C3.39115 3.44445 3.60008 3.65338 3.60008 3.91112V4.85554ZM3.13341 7.33334C3.39115 7.33334 3.60008 7.54227 3.60008 7.8C3.60008 10.1198 5.48026 12 7.80008 12C9.22019 12 10.4746 11.2932 11.2343 10.2111H10.5223C10.2646 10.2111 10.0556 10.0022 10.0556 9.74445C10.0556 9.48672 10.2646 9.27778 10.5223 9.27778H12.0285C12.0353 9.27763 12.0422 9.27763 12.049 9.27778H12.4667C12.7245 9.27778 12.9334 9.48672 12.9334 9.74445V11.6889C12.9334 11.9466 12.7245 12.1556 12.4667 12.1556C12.209 12.1556 12.0001 11.9466 12.0001 11.6889V10.7445C11.073 12.0665 9.5396 12.9333 7.80008 12.9333C4.96479 12.9333 2.66675 10.6353 2.66675 7.8C2.66675 7.54227 2.87568 7.33334 3.13341 7.33334Z"
        fill="#FF3838"
      />
    </g>
  </SVG>
);

export default withTheme(RefreshIcon);
