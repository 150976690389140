import React from 'react';

import { Grid } from '@lp/ds-next';

import Header from '@/components/layout/Header';
import LoginCard from '@/components/layout/LoginCard';
import PageTitle from '@/components/layout/PageTitle';
import PublicMain from '@/components/layout/PublicMain';

const Login = () => (
  <>
    <Header />
    <PublicMain>
      <Grid container spacing={3} justifyContent="center" alignItems="center">
        <PageTitle title="LOGIN" />
        <LoginCard />
      </Grid>
    </PublicMain>
  </>
);

export default Login;
