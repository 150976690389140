import React, { useCallback, useState } from 'react';

import { Grid } from '@lp/ds-next';
import { ColumnProps } from 'antd/lib/table';

import AddServiceLevelModal from '../components/AddServiceLevelModal';
import DuplicateServiceLevelModal from '../components/DuplicateServiceLevelModal';
import EditServiceLevelModal from '../components/EditServiceLevelModal';
import useDeleteServiceLevel from '../hooks/useDeleteServiceLevel';
import useServiceLevel from '../hooks/useServiceLevel';
import useServiceLevels from '../hooks/useServiceLevels';
import { ServiceLevel } from '../models/serviceLevel';
import PageTitle from '@/components/layout/PageTitle';
import Loader from '@/components/Loader';
import StyledTable from '@/components/tables/StyledTable';
import { scopes } from '@/config';
import useAntdColumns from '@/hooks/useAntdColumns';

const ServiceLevelsList = () => {
  const { result, invalidate: fetchServiceLevels } = useServiceLevels();
  const { result: resultDefault } = useServiceLevel(1);
  const deleteServiceLevel = useDeleteServiceLevel();
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [visibleClone, setVisibleClone] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState<any>();

  const handleArchive = useCallback(
    async (e, serviceLevel) => {
      await deleteServiceLevel(serviceLevel.id);
      await fetchServiceLevels();
    },
    [deleteServiceLevel, fetchServiceLevels]
  );

  const columns: ColumnProps<ServiceLevel>[] = useAntdColumns({
    columnsKeys: ['name'],
    addDefaultColumns: true,
    deleteIconProps: {
      isPopover: true,
      onClick: handleArchive,
      requiredScopes: scopes.MANAGE_SERVICE_LEVELS,
    },
    eyeLinkProps: {
      onClick: (record) => {
        setSelectedRecord(record);
        setVisibleEdit(true);
      },
    },
    cloneIconProps: {
      requiredScopes: [scopes.MANAGE_SERVICE_LEVELS],
      onClick: (record) => {
        setSelectedRecord(record);
        setVisibleClone(true);
      },
    },
  });

  if (result.state === 'idle' || result.state === 'loading') {
    return <Loader size={32} />;
  }

  return (
    <Grid container justifyContent="center" spacing={3}>
      <Grid item mobile={11}>
        <PageTitle title="SERVICE LEVELS" />
      </Grid>

      <Grid item container mobile={11} justifyContent="flex-end">
        {resultDefault.state === 'success' && (
          <AddServiceLevelModal
            fetchServiceLevels={fetchServiceLevels}
            defaultConfig={resultDefault.resource}
          />
        )}
      </Grid>

      <Grid item mobile={11}>
        <StyledTable
          rowKey="id"
          dataSource={
            result.state === 'success' &&
            result.resource.items.filter(
              (items) => items.name !== 'Default offer'
            )
          }
          columns={columns}
          size="small"
          loading={result.state !== 'success'}
          scroll={{ x: '100%' }}
          pagination={false}
        />
      </Grid>
      {selectedRecord && visibleEdit && (
        <EditServiceLevelModal
          visible={visibleEdit}
          setVisible={setVisibleEdit}
          initialServiceLevel={selectedRecord}
          fetchServiceLevels={fetchServiceLevels}
        />
      )}
      {selectedRecord && visibleClone && (
        <DuplicateServiceLevelModal
          visible={visibleClone}
          setVisible={setVisibleClone}
          initialServiceLevel={selectedRecord}
          fetchServiceLevels={fetchServiceLevels}
        />
      )}
    </Grid>
  );
};

export default ServiceLevelsList;
