import React from 'react';

import { Grid } from '@lp/ds-next';

import SAContributionDetailContent from './SAContributionDetailContent';
import PageTitle from '@/components/layout/PageTitle';

const SAContributionDetails = () => (
  <Grid container justifyContent="center" spacing={3}>
    <Grid item mobile={11}>
      <PageTitle title="CONTRIBUTION" />
    </Grid>
    <Grid item mobile={11}>
      <SAContributionDetailContent />
    </Grid>
  </Grid>
);

export default SAContributionDetails;
