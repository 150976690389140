import React, { useCallback, useState, useRef, MutableRefObject } from 'react';

import { InfoCircleOutlined } from '@ant-design/icons';
import {
  Grid,
  Stack,
  NewTextField as TextField,
  Select,
  Box,
  ButtonV2 as Button,
  NewTooltip as Tooltip,
  DeliveryKeyIcon,
  Text,
} from '@lp/ds-next';
import { useFormik } from 'formik';
import { object, string } from 'yup';

import TrackingCodesTable from './TrackingCodesTable';
import useLPPartner from '../../lp-village/partners/api/useLPPartner';
import { useCreateTrackingCodeOnShipment } from '../api/trackingCode/createTrackingCode';
import { ITrackingCode } from '../types/trackingCode';
import { PhoneNumber } from '@/components/forms/phoneNumber';
import { scopes } from '@/config';
import useAppState from '@/hooks/useAppState';
import useInputFocus from '@/hooks/useInputFocus';

const LANG_OPTIONS = [
  { label: 'EN', value: 'EN' },
  { label: 'FR', value: 'FR' },
  { label: 'DE', value: 'DE' },
];
const INITIAL_STATE_TRACKINGCODE: ITrackingCode = {
  email: '',
  firstName: '',
  lastName: '',
  lang: 'EN',
  role: 1,
  shipmentId: '',
  phoneNumber: '',
};

const validationSchema = object().shape({
  firstName: string().required('First Name is required!'),
  lastName: string().required('Last name is required!'),
  email: string().email('Invalid email format!').required('Email is required!'),
});

type Props = {
  id: string;
  contractStateStatus: boolean;
  partnerId: string;
  openingCode?: string;
};

const TrackingCodeForm = ({
  id,
  contractStateStatus,
  partnerId,
  openingCode,
}: Props) => {
  const [ref] = useInputFocus({ initialFocus: true });

  const userScopes = useAppState((state) => state.scopes);
  const canViewDeliveryKey = userScopes.includes(scopes.VIEW_DELIVERY_KEY);

  const [trackingCode, setTrackingCode] = useState<any>(
    INITIAL_STATE_TRACKINGCODE
  );
  const [displayDeliveryKeyForm, setDisplayDeliveryKeyForm] =
    useState<boolean>(false);

  const tableRef: MutableRefObject<HTMLDivElement | null> = useRef(null);

  const {
    partnerState: { loading, data },
  } = useLPPartner(partnerId!);

  const { mutate: createTrackingCode, isPending } =
    useCreateTrackingCodeOnShipment({
      shipmentId: id,
    });

  const handleTextChange = useCallback(
    (e) => {
      setTrackingCode({ ...trackingCode, [e.target.name]: e.target.value });
    },
    [setTrackingCode, trackingCode]
  );

  const shareDeliveryKeyform = useFormik({
    initialValues: trackingCode,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      values.shipmentId = id;

      createTrackingCode({ values, shipmentId: id });

      setTrackingCode(INITIAL_STATE_TRACKINGCODE);
      setDisplayDeliveryKeyForm(false);
    },
    initialTouched: {
      email: false,
      firstName: false,
      lastName: false,
      lang: false,
      phoneNumber: false,
    },
  });

  return (
    <Box pb="2rem">
      <Stack
        direction="row"
        justifyContent={
          openingCode || displayDeliveryKeyForm ? 'space-between' : 'flex-end'
        }
        alignItems="center"
      >
        {!displayDeliveryKeyForm && openingCode && (
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              padding: '.375rem .9375rem',
              gap: '.5rem',
              borderRadius: '.5rem',
              color: 'custom.neutral.white.pure',
              bgcolor: 'info.main',
              opacity: canViewDeliveryKey ? 1 : 0.3,
            }}
          >
            <DeliveryKeyIcon size="1.125rem" />
            <Text variant="titleXS" color="custom.neutral.white.pure">
              Opening code:{' '}
              <Box component="span" sx={{ fontStyle: 'italic' }}>
                {canViewDeliveryKey ? openingCode : 'Lack of permissions'}
              </Box>
            </Text>
          </Stack>
        )}

        {displayDeliveryKeyForm && (
          <Box
            component="form"
            onSubmit={shareDeliveryKeyform.handleSubmit}
            data-testid="delivery-key-form"
            sx={{ width: { mobile: '100%', tablet: '70%' } }}
            mb="1rem"
          >
            <Grid container pb="1rem" spacing="1rem">
              <Grid item mobile={6}>
                <TextField
                  ref={ref}
                  onChange={handleTextChange}
                  label="First Name"
                  name="firstName"
                  value={shareDeliveryKeyform.values.firstName}
                  data-testid="delivery-key-firstName"
                  required
                  onBlur={shareDeliveryKeyform.handleBlur}
                  error={
                    shareDeliveryKeyform.touched.firstName &&
                    !!shareDeliveryKeyform.errors.firstName
                  }
                  helperText={shareDeliveryKeyform.errors.firstName as any}
                />
              </Grid>
              <Grid item mobile={6}>
                <TextField
                  onChange={handleTextChange}
                  label="Last Name"
                  name="lastName"
                  value={shareDeliveryKeyform.values.lastName}
                  data-testid="delivery-key-lastName"
                  required
                  onBlur={shareDeliveryKeyform.handleBlur}
                  error={
                    shareDeliveryKeyform.touched.lastName &&
                    !!shareDeliveryKeyform.errors.lastName
                  }
                  helperText={shareDeliveryKeyform.errors.lastName as any}
                />
              </Grid>
            </Grid>
            <Grid container pb="1rem" spacing="1rem">
              <Grid item mobile={6}>
                <TextField
                  onChange={handleTextChange}
                  label="Email"
                  name="email"
                  value={shareDeliveryKeyform.values.email}
                  data-testid="delivery-key-email"
                  required
                  onBlur={shareDeliveryKeyform.handleBlur}
                  error={
                    shareDeliveryKeyform.touched.email &&
                    !!shareDeliveryKeyform.errors.email
                  }
                  helperText={shareDeliveryKeyform.errors.email as any}
                />
              </Grid>
              <Grid item mobile={6}>
                <PhoneNumber
                  label="Phone number"
                  defaultCountryCode="FR"
                  name="phoneNumber"
                  value={shareDeliveryKeyform.values.phoneNumber}
                  data-testid="delivery-key-phoneNumber"
                  fullWidth
                  disabled={!loading && !data.sms_enabled}
                  countrySelectorMaxHeight="12.5rem"
                  onChange={(value) => {
                    shareDeliveryKeyform.setFieldValue('phoneNumber', value);
                  }}
                  error={!!shareDeliveryKeyform.errors.phone_number}
                  helperText={shareDeliveryKeyform.errors.phone_number as any}
                  InputProps={{
                    endAdornment: (
                      <Tooltip title="Optional: If empty, then no text message is sent.">
                        <InfoCircleOutlined />
                      </Tooltip>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item mobile={6}>
                <Select
                  label="Language"
                  options={LANG_OPTIONS}
                  name="lang"
                  value={shareDeliveryKeyform.values.lang || 'EN'}
                  onChange={shareDeliveryKeyform.handleChange}
                  data-testid="delivery-key-langue"
                />
              </Grid>
              <Grid item mobile={6}>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  pl=".3125rem"
                  gap="0.5rem"
                >
                  <Button
                    variant="secondary"
                    onClick={() => {
                      setDisplayDeliveryKeyForm(!displayDeliveryKeyForm);
                      setTrackingCode(INITIAL_STATE_TRACKINGCODE);
                    }}
                    data-testid="delivery-key-cta-create-cancel"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={shareDeliveryKeyform.submitForm}
                    disabled={
                      !shareDeliveryKeyform.values?.firstName ||
                      !shareDeliveryKeyform.values?.lastName ||
                      !shareDeliveryKeyform.values?.email ||
                      isPending
                    }
                    data-testid="delivery-key-cta-create"
                  >
                    Send
                  </Button>
                </Stack>
              </Grid>
            </Grid>
            {contractStateStatus && (
              <Text variant="titleS" pt="1rem" color="warning.main">
                Check contract state of shipment before creating delivery key
              </Text>
            )}
          </Box>
        )}

        {!displayDeliveryKeyForm && (
          <Stack direction="row" justifyContent="flex-end" pb="1rem">
            <Tooltip title=" Share a new delivery key">
              <Button
                data-testid="delivery-key-cta-share"
                size="small"
                onClick={() => {
                  setDisplayDeliveryKeyForm(!displayDeliveryKeyForm);
                  setTimeout(() => {
                    if (tableRef.current) {
                      tableRef.current.scrollIntoView({ behavior: 'smooth' });
                    }
                  }, 0);
                }}
                disabled={displayDeliveryKeyForm}
              >
                +
              </Button>
            </Tooltip>
          </Stack>
        )}
      </Stack>
      <Box ref={tableRef}>
        <TrackingCodesTable canViewDeliveryKey={canViewDeliveryKey} />
      </Box>
    </Box>
  );
};

export default TrackingCodeForm;
