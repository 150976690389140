import React from 'react';

import { withTheme } from 'styled-components';

import SVG from './SVG';

const ValidatedIcon = (props) => (
  <SVG width="1rem" height="1rem" viewBox="0 0 16 16" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM11.7304 5.88195C11.4926 5.65116 11.1127 5.65683 10.882 5.89461L7.392 9.489L5.70335 8.03909L5.61523 7.97617C5.36977 7.83238 5.04915 7.87998 4.85727 8.10347C4.64141 8.35488 4.67023 8.73369 4.92165 8.94955L6.89654 10.6452L6.98773 10.7129C7.3031 10.9143 7.72399 10.8712 7.99174 10.5954L11.743 6.73039L11.8112 6.64626C11.9697 6.41003 11.9417 6.0871 11.7304 5.88195Z"
      fill="#00BF6F"
    />
  </SVG>
);

export default withTheme(ValidatedIcon);
