import React, { useCallback, useEffect } from 'react';

import { Grid } from '@lp/ds-next';
import { ColumnProps } from 'antd/es/table';
import get from 'lodash/get';

import CreateQualityCheckType from '../components/CreateQualityCheckType';
import useQualityCheckType from '../hooks/useQualityCheckType';
import useQualityCheckTypeStore from '../hooks/useQualityCheckTypeStore';
import { ZonesNames } from '../models/qualityCheck';
import PageTitle from '@/components/layout/PageTitle';
import StyledTable from '@/components/tables/StyledTable';
import {
  getCurrentPage,
  getOffset,
  parseAntdSorter,
} from '@/helpers/antdTable';
import useAntdColumns from '@/hooks/useAntdColumns';
import useWindowSize from '@/hooks/useWindowSize';

const QualityCheckTypeList = () => {
  const { loading, getQualityCheckTypes } = useQualityCheckType();
  const { rowData, defaultSort, dispatch, pageSize, offset, total } =
    useQualityCheckTypeStore();
  const windowSize = useWindowSize();

  useEffect(() => {
    getQualityCheckTypes();
  }, [getQualityCheckTypes]);

  useEffect(
    () => () => {
      dispatch({ type: 'reset' });
    },
    [dispatch]
  );

  const pagination = {
    current: getCurrentPage(pageSize, offset),
    pageSize,
    total,
    showSizeChanger: true,
  };

  const columns: ColumnProps<any>[] = useAntdColumns({
    columnsKeys: [
      'qualityCheckTypeId',
      'name',
      'description',
      'expectedLevel',
      'zone',
    ],
    addDefaultColumns: true,
    columnsSpecialProps: {
      zone: {
        render: (text) => ZonesNames[text],
      },
    },
    defaultSort: defaultSort,
    eyeLinkProps: { to: '/quality-check-types' },
  });

  const handleTableChange = useCallback(
    (pagination, filters, sorter) => {
      const [sortField, sortOrder] = parseAntdSorter(sorter);
      dispatch({
        type: 'updateSorting',
        args: { sortBy: sortField, order: sortOrder },
      });
      dispatch({
        type: 'updatePagination',
        args: {
          offset: getOffset(pagination.pageSize, pagination.current),
          pageSize: pagination.pageSize,
        },
      });
    },
    [dispatch]
  );

  return (
    <Grid container justifyContent="center" spacing={3}>
      <Grid item mobile={11}>
        <PageTitle title="QUALITY CHECK TYPES" />
      </Grid>
      <Grid container justifyContent="flex-end" item mobile={11}>
        <CreateQualityCheckType />
      </Grid>

      <Grid item mobile={11}>
        <StyledTable
          rowKey="id"
          dataSource={rowData}
          columns={columns}
          size="small"
          loading={loading}
          scroll={{ y: get(windowSize, 'height', 0) * 0.65 }}
          onChange={handleTableChange}
          pagination={pagination}
        />
      </Grid>
    </Grid>
  );
};

export default QualityCheckTypeList;
