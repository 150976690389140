export const PARTNER_TYPES = {
  PRO: 'PRO',
  TEST: 'TEST',
  PERSONAL: 'PERSONAL',
};

export const PARTNER_STATUS = {
  PENDING: 'PENDING',
  BLOCKED: 'BLOCKED',
  VALIDATED: 'VALIDATED',
};

export const PARTNER_PROFILE = {
  EXTERNAL: 'EXTERNAL',
  INTERNAL: 'INTERNAL',
};

export const PARTNER_ERP_SYNCHRONISATION_STATE = {
  SYNCHRONISED: 'SYNCHRONISED',
  NOT_SYNCHRONISED: 'NOT_SYNCHRONISED',
};

export const PARTNER_ACCOUNT_STATUS = {
  COMPLETED: 'COMPLETED',
  NOT_COMPLETED: 'NOT_COMPLETED',
};
