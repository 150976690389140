import React, { useCallback, useContext, useEffect } from 'react';

import { Grid } from '@lp/ds-next';
import { ColumnProps } from 'antd/lib/table';
import get from 'lodash/get';
import styled from 'styled-components';

import useLPUsersStore, { TLPAccountSortBy } from '../hooks/useLPUsersStore';
import ServerSearch from '@/components/ServerSearch';
import StyledTable from '@/components/tables/StyledTable';
import TablesTotals from '@/components/TablesTotals';
import {
  getCurrentPage,
  getOffset,
  parseAntdSorter,
} from '@/helpers/antdTable';
import useWindowSize from '@/hooks/useWindowSize';
import { AccountsTotalContext } from '@/shared/providers/accountsTotalProvider';

const Wrapper = styled.div`
  width: 60%;
  min-width: 30rem;
`;

const LPAccountUsersTable = ({
  withSearch = false,
  columns,
  rowKey = 'id',
}: {
  withSearch?: boolean;
  columns: ColumnProps<any>[];
  rowKey?: ((input: any) => string) | string;
}) => {
  const rowData = useLPUsersStore((state) => state.rowData);
  const loading = useLPUsersStore((state) => state.loading);
  const pageSize = useLPUsersStore((state) => state.pageSize);
  const offset = useLPUsersStore((state) => state.offset);
  const total = useLPUsersStore((state) => state.total);
  const searchTerms = useLPUsersStore((state) => state.searchTerms);
  const dispatch = useLPUsersStore((state) => state.dispatch);
  const windowSize = useWindowSize();
  const lTotal = useContext(AccountsTotalContext);

  const pagination = {
    current: getCurrentPage(pageSize, offset),
    pageSize,
    total,
    showSizeChanger: true,
  };
  useEffect(
    () => () => {
      dispatch({ type: 'updateDefaultSort' });
    },
    [dispatch]
  );

  const handleTableChange = useCallback(
    (pagination, filters, sorter) => {
      const [sortField, sortOrder] = parseAntdSorter(sorter);
      dispatch({
        type: 'updateSorting',
        args: { sortBy: sortField as TLPAccountSortBy, order: sortOrder },
      });
      dispatch({
        type: 'updatePagination',
        args: {
          offset: getOffset(pagination.pageSize, pagination.current),
          pageSize: pagination.pageSize,
        },
      });
      dispatch({
        type: 'updateFilters',
        args: {
          filters: {
            accountVerified: get(filters, 'accountVerified', null),
            invest: get(filters, 'invest', null),
          },
        },
      });
    },
    [dispatch]
  );

  return (
    <Grid container justifyContent="center" spacing={1}>
      {withSearch && (
        <Grid item mobile={12}>
          <Wrapper>
            <ServerSearch
              fields={[
                { key: 'firstName', placeholder: 'First Name' },
                { key: 'lastName', placeholder: 'Last Name' },
                { key: 'email', placeholder: 'Email' },
              ]}
              dispatch={dispatch}
              storeSearchTerms={searchTerms}
            />
          </Wrapper>
        </Grid>
      )}
      <Grid item mobile={12}>
        <StyledTable
          rowKey={rowKey}
          dataSource={rowData}
          columns={columns}
          size="small"
          pagination={pagination}
          loading={loading}
          scroll={{ y: get(windowSize, 'height', 0) * 0.65 }}
          onChange={handleTableChange}
          footer={() => <TablesTotals total={total} gTotal={lTotal} />}
        />
      </Grid>
    </Grid>
  );
};

export default LPAccountUsersTable;
