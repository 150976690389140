import React from 'react';

import { Grid } from '@lp/ds-next';

import WrapperAccountsTable from '../components/WrapperAccountTable';
import PageTitle from '@/components/layout/PageTitle';

export const LPAccountsListView = () => (
  <Grid container justifyContent="center" spacing={3}>
    <Grid item mobile={11}>
      <PageTitle title="LP ACCOUNT USERS" />
    </Grid>
    <Grid container item mobile={11}>
      <WrapperAccountsTable />
    </Grid>
  </Grid>
);
