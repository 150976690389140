import React from 'react';

import { Grid } from '@lp/ds-next';

import LPAccountUserDetailsContent from '../components/LPAccountUserDetailsContent';
import PageTitle from '@/components/layout/PageTitle';

const LPAccountUserDetails = (_) => (
  <Grid container justifyContent="center" spacing={3}>
    <Grid item mobile={11}>
      <PageTitle title="LP ACCOUNT USER DETAILS" />
    </Grid>
    <Grid item mobile={11}>
      <LPAccountUserDetailsContent />
    </Grid>
  </Grid>
);

export default LPAccountUserDetails;
