import React from 'react';

import { Grid } from '@lp/ds-next';

import ShipmentDetailsContent from '../components/ShipmentDetailsContent';
import PageTitle from '@/components/layout/PageTitle';

const ShipmentDetailsView = () => (
  <Grid container justifyContent="center" spacing={3}>
    <Grid item mobile={11}>
      <PageTitle title="SHIPMENT DETAILS" />
    </Grid>
    <Grid item mobile={11}>
      <ShipmentDetailsContent />
    </Grid>
  </Grid>
);

export default ShipmentDetailsView;
