import pickBy from 'lodash/pickBy';
import transform from 'lodash/transform';

export const checkEmptyFields = (obj: { [key: string]: string }) =>
  pickBy(obj, (val) => !val);

export const overwriteObject = (obj: { [key: string]: string }, newVal) =>
  transform(
    obj,
    (out, _, key) => {
      out[key] = newVal;
    },
    {}
  );

export const hasEmptyFields = (formData) =>
  !!Object.values(formData).filter(
    (field) => field === undefined || field === ''
  ).length;
