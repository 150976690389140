import React from 'react';

import { AccountIcon, Box } from '@lp/ds-next';
const Avatar = ({ height, width, ...props }) => (
  <Box color="custom.neutral.black.20">
    <AccountIcon style={{ height, width }} {...props} />
  </Box>
);

export default Avatar;
