import React, { useCallback, useRef } from 'react';

import { Col, Divider, Input, Popconfirm, Row, Tag } from 'antd';

import { TModifiedBoxNote } from '../../models/boxNote';
import Spacer from '@/components/layout/Spacer';
import PillButton from '@/components/PillButton';

const { TextArea } = Input;

interface IBoxNote {
  note: TModifiedBoxNote;
  dispatch: any;
  submitNote: any;
  deleteNote: any;
}

const BoxNote = ({ note, submitNote, deleteNote, dispatch }: IBoxNote) => {
  const initialText = useRef<string>(note?.note);
  const handleChange = useCallback(
    (e) => {
      dispatch({
        type: 'updateSingleNote',
        args: { ...note, note: e?.target?.value, isDirty: true },
      });
    },
    [note, dispatch]
  );

  const handleSubmit = useCallback((_) => submitNote(note), [note, submitNote]);

  const handleCancel = useCallback(
    (_) => {
      dispatch({
        type: 'updateSingleNote',
        args: { ...note, note: initialText.current, isDirty: false },
      });
    },
    [dispatch, note]
  );

  const handleDelete = useCallback((_) => deleteNote(note), [note, deleteNote]);

  return (
    <div>
      {note.parsedCreatedAt && (
        <Tag color="success">Created At: {note.parsedCreatedAt}</Tag>
      )}
      {note.parsedUpdatedAt && (
        <Tag color="processing">Updated At: {note.parsedUpdatedAt}</Tag>
      )}
      {note.createdBy?.email && <Tag>Created By: {note.createdBy.email}</Tag>}

      {note?.updatedBy?.email && <Tag>Updated By: {note.updatedBy.email}</Tag>}

      {note.isDirty && <Tag color="error">unsaved changes</Tag>}
      <TextArea
        placeholder="Add a note to THE BOX..."
        autoSize={{ minRows: 3, maxRows: 12 }}
        value={note.note}
        onChange={handleChange}
      />
      <Spacer v="1rem" />
      <Row gutter={24}>
        {note.id !== '' && (
          <Col>
            <Popconfirm
              title="Really delete this note?"
              onConfirm={handleDelete}
              okText="Yes"
              cancelText="No"
            >
              <PillButton danger>Delete</PillButton>
            </Popconfirm>
          </Col>
        )}
        <Col>
          <PillButton type="default" onClick={handleCancel}>
            Cancel
          </PillButton>
        </Col>
        <Col>
          <PillButton onClick={handleSubmit}>Submit</PillButton>
        </Col>
      </Row>
      <Spacer v="1rem" />
      <Divider />
      <Spacer v="1rem" />
    </div>
  );
};

export default BoxNote;
