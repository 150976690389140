import React, { useCallback, useEffect, useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import styled from 'styled-components';

import BoxLifecycleHistory from './BoxLifecycleHistory';
import LifeCycleTypeForm from './LifeCycleTypeForm';
import useBoxesLifecycleStore from '../hooks/useBoxesLifecycleStore';
import useBoxLifecycle from '../hooks/useBoxLifecycle';
import PillButton from '@/components/PillButton';
import ScopedCta from '@/components/ScopedCta';
import { scopes } from '@/config';

const StyledRow = styled(Row)`
  margin-right: 0px !important;
  padding-bottom: 5px;
  margin-right: 10px;
`;

const BoxLifecycle = ({ boxId }: { boxId: string }) => {
  const { getLifecycleStatuses, addLifecycleStatus, loadingCreate } =
    useBoxLifecycle();

  const { rowData: lifecycle_statuses, dispatch } = useBoxesLifecycleStore();

  const [lifeCycleStatut, setLifeCycleStatus] = useState<{
    status: string;
    sub_status?: string;
  }>();
  const [lastStatutState, setLastStatusState] = useState<any>(undefined);

  useEffect(() => {
    getLifecycleStatuses(boxId);
  }, [getLifecycleStatuses, boxId]);

  useEffect(() => {
    if (lifecycle_statuses.length) {
      return setLastStatusState(lifecycle_statuses.slice(0, 1)[0]);
    }
  }, [lifecycle_statuses, setLastStatusState]);

  useEffect(
    () => () => {
      dispatch({ type: 'reset' });
    },
    [dispatch]
  );

  const updateLifeCycleStatus = useCallback((status, sub_status) => {
    setLifeCycleStatus({ status, sub_status });
  }, []);

  const handleSubmitStatus = async () => {
    if (lifeCycleStatut?.status) {
      const { success } = await addLifecycleStatus(boxId, lifeCycleStatut);
      if (success) await getLifecycleStatuses(boxId);
    }
  };

  return (
    <>
      <StyledRow justify="end">
        <Col span={5}>
          <LifeCycleTypeForm
            setLifeCycleTypeCode={updateLifeCycleStatus}
            lastState={lastStatutState}
          />
        </Col>
        <Col span={3}>
          <ScopedCta
            component={PillButton}
            icon={<PlusOutlined />}
            onClick={handleSubmitStatus}
            requiredScopes={[scopes.EDIT_BOX_CONFIG]}
            disabled={loadingCreate || !lifeCycleStatut?.status}
            data-testid="btn-add-status"
          >
            Add Status
          </ScopedCta>
        </Col>
      </StyledRow>

      <StyledRow>
        <Col span={12} offset={6} data-testid="lifecycle-timelines">
          <BoxLifecycleHistory lifecycleStatuses={lifecycle_statuses} />
        </Col>
      </StyledRow>
    </>
  );
};

export default BoxLifecycle;
