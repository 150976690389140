export class lpvillageERPSyncForbidden extends Error {
  public static readonly code = 'lpvillageERPSyncForbidden';

  constructor() {
    super('The Partner is not allowed to synchrozize with ERP');
  }
}

export class LpvillageERPSyncSylobTechnicalFailure extends Error {
  public static readonly code = 'lpvillageERPSyncSylobTechnicalFailure';

  constructor() {
    super('Internal technical error on the Sylob side');
  }
}

export class LpvillageERPSyncSylobDetailedFailure extends Error {
  public static readonly code = 'lpvillageERPSyncSylobDetailedFailure';

  constructor() {
    super('Internal non-technical error (missing company name...)');
  }
}

export class LpvillageERPSyncAddresses extends Error {
  public static readonly code = 'lpvillageERPSyncAddresses';

  constructor() {
    super('Error localized at the addresses synchronization level');
  }
}

export class LpvillageERPSyncPartnerIDCard extends Error {
  public static readonly code = 'lpvillageERPSyncPartnerIDCard';

  constructor() {
    super('Error localized at the partner ID card synchronization level');
  }
}
export class LpvillageERPSyncDate extends Error {
  public static readonly code = 'lpvillageERPSyncDate';

  constructor() {
    super('Error localized at the synchronization date level');
  }
}

export const syncErrors = [
  lpvillageERPSyncForbidden,
  LpvillageERPSyncSylobTechnicalFailure,
  LpvillageERPSyncSylobDetailedFailure,
  LpvillageERPSyncAddresses,
  LpvillageERPSyncPartnerIDCard,
  LpvillageERPSyncDate,
];
