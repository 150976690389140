import React, { useState, useEffect } from 'react';

import { Select, SelectProps, Checkbox, Tag, Space, Typography } from 'antd';

import { capitalizeFirstLetter } from '@/utils/format';

const { Text } = Typography;

interface DropdownV2Props extends SelectProps {
  options: { label: string; value: string }[];
  defaultValue?: string[] | string | undefined;
  value?: string[] | string | undefined;
  onChange?: (value: string[]) => void;
}

export const DropdownV2 = ({
  options,
  defaultValue,
  value,
  onChange,
  ...rest
}: DropdownV2Props) => {
  const [selectedValues, setSelectedValues] = useState<Set<string>>(
    new Set(defaultValue)
  );

  useEffect(() => {
    if (value) {
      setSelectedValues(new Set(Array.isArray(value) ? value : [value]));
    }
  }, [value]);

  const handleChange = (selected: string[]) => {
    setSelectedValues(new Set(selected));
    if (onChange) {
      onChange(selected);
    }
  };

  const handleOptionClick = (optionValue: string) => {
    const newSelectedValues = new Set(selectedValues);
    if (newSelectedValues.has(optionValue)) {
      newSelectedValues.delete(optionValue);
    } else {
      newSelectedValues.add(optionValue);
    }
    handleChange(Array.from(newSelectedValues));
  };

  return (
    <Select
      {...rest}
      maxTagCount={rest.maxTagCount || 'responsive'}
      value={Array.from(selectedValues)}
      style={{ width: '100%' }}
      onChange={handleChange}
      mode={rest.mode || 'tags'}
      tagRender={(props) => {
        const { label, closable, onClose, value, isMaxTag } = props;

        return (
          <Tag
            closable={closable}
            color="default"
            onClose={() => {
              const newSelectedValues = Array.isArray(selectedValues)
                ? selectedValues.filter((val) => val !== label)
                : [];
              handleChange(newSelectedValues);
              if (onClose) {
                onClose();
              }
            }}
            onMouseDown={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            {isMaxTag ? label : capitalizeFirstLetter(value)}
          </Tag>
        );
      }}
      dropdownRender={() => (
        <Space direction="vertical" align="start">
          {options.map(({ label, value }) => (
            <Space
              key={value}
              direction="horizontal"
              align="center"
              size="large"
              style={{
                width: '100%',
                marginLeft: '0.75rem',
                padding: '0.25rem',
              }}
              className="antd-option"
            >
              <Checkbox
                onChange={() => handleOptionClick(value)}
                checked={selectedValues?.has(value) ?? false}
                data-testid={`partner-filter-form-status-${value}`}
              >
                <Text>{label}</Text>
              </Checkbox>
            </Space>
          ))}
        </Space>
      )}
    />
  );
};
