import React, { useCallback, useEffect, useState } from 'react';

import { Grid } from '@lp/ds-next';
import { Tabs } from 'antd';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { IShipment } from '../../../features/shipments/models/shipment';
import BoxChecklist from '../components/BoxChecklist';
import BoxConfig from '../components/BoxConfig';
import BoxesSticker from '../components/BoxesSticker';
import BoxFirmwareHistory from '../components/BoxFirmwareHistory';
import BoxHardware from '../components/BoxHardware';
import BoxLastLocationMap from '../components/BoxLastLocationMap';
import BoxLifecycle from '../components/BoxLifecycle';
import BoxManagement from '../components/BoxManagement';
import BoxNotes from '../components/BoxNotes/BoxNotes';
import BoxQualityCheck from '../components/BoxQualityCheck';
import BoxTelemetryEvents from '../components/BoxTelemetryEvents';
import GoToBoxShipmentButton from '../components/GoToBoxShipmentButton';
import ProductDetailsForm from '../components/ProductDetailsForm';
import RemoteControl from '../components/RemoteControl/RemoteControl';
import SimVersion from '../components/SimVersions';
import useGetBox from '../hooks/useGetBox';
import PageTitle from '@/components/layout/PageTitle';
import { scopes } from '@/config';
import { useApiClient } from '@/helpers/ApiClients';
import { IPaginatedStore } from '@/helpers/paginatedStoreHelpers';
import useAppState from '@/hooks/useAppState';

const ActionButtonBar = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ProductDetailView = () => {
  const { id } = useParams<{ id: string }>();
  const { box } = useGetBox(id);
  const [tab, setTab] = useState('telemetry');
  const [tabItems, setTabItems] = useState<any[]>([]);
  const shipmentService = useApiClient('shipment');
  const [currentShipment, setCurrentShipment] = useState<IShipment>();
  const [nbShipment, setNbShipment] = useState<number>(0);
  const userScopes = useAppState((state) => state.scopes);

  const findCurrentShipment = useCallback(
    (data) => {
      const currentShipment = data.items.find(
        (ship) => ship.contractState === 'DEPLOYED'
      );
      if (currentShipment) {
        setCurrentShipment(currentShipment);
      }
    },
    [setCurrentShipment]
  );

  const refreshBoxShipment = useCallback(
    (boxId: string): void => {
      shipmentService
        .get<
          IPaginatedStore<any, {}>
        >(`/shipments?boxId=${boxId}&lastdeployed=true&sortBy=deployedAt&order=asc`)
        .then(({ data }) => {
          findCurrentShipment(data);
          setNbShipment(data.total);
        });
    },
    [shipmentService, findCurrentShipment]
  );

  useEffect(() => {
    if (box.id) {
      refreshBoxShipment(box.id);
      const items = [
        {
          label: 'Telemetry Events',
          key: 'telemetry',
          children: <BoxTelemetryEvents id={box.id} />,
          forceRender: true,
        },
        {
          label: 'Firmware',
          key: 'firmware',
          children: <BoxFirmwareHistory box={box} />,
        },
        {
          label: 'Hardware',
          key: 'hardware',
          children: <BoxHardware box={box} />,
        },
        {
          label: 'Connectivity',
          key: 'connectivity',
          children: <SimVersion box={box} />,
        },
        {
          label: 'Product Sticker',
          key: 'production-sticker',
          children: <BoxesSticker box={box} />,
        },
        {
          label: 'Box Status',
          key: 'box-status',
          children: <BoxChecklist boxId={box.id!} />,
        },
        {
          label: 'Notes',
          key: 'notes',
          children: <BoxNotes boxId={box.id!} />,
        },
        {
          label: 'Firmware Config',
          key: 'firmware-config',
          children: <BoxConfig boxId={box.id!} />,
        },
        {
          label: 'Box Quality Check',
          key: 'box-quality-check',
          children: <BoxQualityCheck boxId={box.id!} />,
        },
        {
          label: 'Lifecycle',
          key: 'lifecycle',
          children: <BoxLifecycle boxId={box.lpUI} />,
        },
        {
          label: 'Map',
          key: 'map',
          children: <BoxLastLocationMap boxId={box.id} thingName={box.lpUI} />,
        },
      ];

      const responsibilityHistoryTab = {
        label: 'Responsibility History',
        key: 'box-management',
        children: <BoxManagement id={box.id} />,
      };

      items.splice(4, 0, responsibilityHistoryTab);

      setTabItems(items);
    }
  }, [
    box,
    findCurrentShipment,
    shipmentService,
    userScopes,
    refreshBoxShipment,
  ]);

  return (
    <Grid container justifyContent="center">
      <Grid item mobile={11}>
        <PageTitle title="PRODUCT DETAILS" />
      </Grid>
      <Grid
        item
        mobile={11}
        container
        justifyContent="flex-end"
        style={{ gap: '0.5rem' }}
      >
        {userScopes.includes(scopes.MANAGE_PRODUCT) &&
          id &&
          currentShipment && <RemoteControl shipment={currentShipment} />}
        <ActionButtonBar>
          <GoToBoxShipmentButton boxId={box.id} shipment={currentShipment} />
        </ActionButtonBar>
      </Grid>
      <Grid item mobile={11}>
        <ProductDetailsForm
          box={box}
          shipment={currentShipment}
          nbShipment={nbShipment}
        />
      </Grid>
      <Grid item mobile={11} style={{ marginTop: '2rem' }}>
        <Tabs
          type="card"
          activeKey={tab}
          onTabClick={(key) => setTab(key)}
          items={tabItems}
          data-testid="product-details-tabs"
        />
      </Grid>
    </Grid>
  );
};

export default ProductDetailView;
