import React, { useEffect, useMemo, useState } from 'react';

import { Stack } from '@lp/ds-next';
import { DatePicker, TimePicker } from 'antd';
import { getUnixTime } from 'date-fns';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'; // Plugin pour gérer les timestamps Unix
import toSafeInteger from 'lodash/toSafeInteger';

dayjs.extend(utc);

const parseDateTimeStrings = (dateString, timeString) => {
  const dateArray = dateString.split('-').map((item) => toSafeInteger(item));
  const timeArray = timeString.split(':').map((item) => toSafeInteger(item));
  const dateTime = new Date(
    dateArray[0],
    dateArray[1] - 1,
    dateArray[2],
    timeArray[0],
    timeArray[1],
    timeArray[2]
  );
  const unixDateTime = getUnixTime(dateTime);

  return unixDateTime;
};

export const futureDateCheck = (current) =>
  current && current > dayjs().endOf('day');
export const pastDateCheck = (current) =>
  current && current < dayjs().startOf('day');

interface DateTimePickerProps {
  setUnixTimeStamp: (e: number | null) => void;
  disabledFutureDate?: boolean;
  disabledPastDate?: boolean;
  useDefaultTime?: boolean;
  withTime?: boolean;
  value?: number | null;
}

const DateTimePicker = ({
  setUnixTimeStamp,
  disabledFutureDate = false,
  disabledPastDate = false,
  useDefaultTime = true,
  withTime = true,
  value,
}: DateTimePickerProps) => {
  const [date, setDate] = useState<string>('');
  const [time, setTime] = useState<string>(useDefaultTime ? '12:00:00' : '');

  useEffect(() => {
    if (value) {
      const dateValue = dayjs.unix(value);
      setTime(dateValue.format('HH:mm:ss'));
      setDate(dateValue.format('YYYY-MM-DD'));
    }
  }, [value]);

  const disabledDate = useMemo(() => {
    if (disabledFutureDate) {
      return disabledFutureDate ? futureDateCheck : undefined;
    }

    if (disabledPastDate) {
      return disabledPastDate ? pastDateCheck : undefined;
    }

    return undefined;
  }, [disabledFutureDate, disabledPastDate]);

  useEffect(() => {
    if (date && !time && !withTime) {
      setUnixTimeStamp(parseDateTimeStrings(date, dayjs().format('HH:mm:ss')));

      return;
    }
    if (!date || !time) {
      setUnixTimeStamp(null);

      return;
    }

    setUnixTimeStamp(parseDateTimeStrings(date, time));
  }, [date, time, setUnixTimeStamp, withTime]);

  return (
    <Stack direction="row" gap="1rem">
      <DatePicker
        disabledDate={disabledDate}
        onChange={(_, dateString) => {
          setDate(dateString.toString());
        }}
        value={
          value
            ? dayjs.unix(value)
            : date
              ? dayjs(date, 'YYYY-MM-DD')
              : undefined
        }
      />

      <TimePicker
        defaultValue={
          useDefaultTime ? dayjs().startOf('day').hour(12).minute(0) : undefined
        }
        onChange={(_, timeString) => {
          setTime(timeString.toString());
        }}
        value={
          value ? dayjs.unix(value) : time ? dayjs(time, 'HH:mm:ss') : undefined
        }
      />
    </Stack>
  );
};

export default DateTimePicker;
