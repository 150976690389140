import { useQuery } from '@tanstack/react-query';

import { ILifecycleSubStatus } from '../../models/boxLifecycle';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

export const getLifecycleSubStatuses = async ({
  makeRequest,
}: {
  makeRequest: any;
}): Promise<ILifecycleSubStatus | null> => {
  const { data, error } = await makeRequest({
    path: `/products/lifecycle-status/transitions`,
  });

  if (error) {
    throw new Error(error);
  }

  return data;
};

type QueryFnTypeSubStatuses = typeof getLifecycleSubStatuses;

type UseLifeCyleSubStatusesOptions = {
  config?: QueryConfig<QueryFnTypeSubStatuses>;
};

export const useLifecycleSubStatuses = ({
  config,
}: UseLifeCyleSubStatusesOptions = {}) => {
  const [, makeRequest] = useImperativeRequestWrapper('deviceV3');

  return useQuery<ExtractFnReturnType<QueryFnTypeSubStatuses>>({
    ...config,
    queryKey: ['lifecycleSubStatus'],
    queryFn: () => getLifecycleSubStatuses({ makeRequest }),
  });
};
