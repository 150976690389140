import React, { useCallback, useMemo, useState } from 'react';

import { Stack, Text } from '@lp/ds-next';
import forEach from 'lodash/forEach';
import styled from 'styled-components';

import CountrySelector from './forms/CountrySelector';
import SearchField from './forms/SearchField';
import PartnerAutocomplete from './ParnterAutocomplete';
import SearchButton from './SearchButton';
import SearchResetButton from './SearchResetButton';

interface IField {
  label?: string;
  placeholder?: string;
  key: string;
}

interface IServerSearch {
  fields: IField[];
  dispatch: any;
  storeSearchTerms: {
    [key: string]: any;
  };
  label?: string;
  setSelectedRowKeys?;
}

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 1rem;
`;

const SearchWrapper = styled.div`
  flex: 1;
  margin-right: 1rem;
`;

const ButtonWrapper = styled.div`
  margin-right: 0.5rem;
`;

const trimSearchTerms = (searchTerms) => {
  const result = {};
  forEach(searchTerms, (value: string, key: string) => {
    result[key] = !Array.isArray(value) ? value.trim() : value;
  });

  return result;
};

const ServerSearch = ({
  fields,
  dispatch,
  storeSearchTerms,
  label = 'Search by:',
  setSelectedRowKeys = undefined,
}: IServerSearch) => {
  const INITIAL_STATE = useMemo(
    () =>
      fields.reduce((a, b) => {
        a[b.key] = storeSearchTerms[b.key] || '';

        return a;
      }, {}),
    [fields, storeSearchTerms]
  );

  const CLEARED_STATE = useMemo(
    () =>
      fields.reduce((a, b) => {
        a[b.key] = '';

        return a;
      }, {}),
    [fields]
  );

  const [searchTerms, setSearchTerms] = useState<{ [key: string]: string }>(
    INITIAL_STATE
  );

  const [parnterIdsSelected, setParnterIdsSelected] = useState<any>([]);

  const handleChange = useCallback(
    (event) => {
      setSearchTerms({
        ...searchTerms,
        [event.target.name]: event.target.value,
      });
    },
    [searchTerms, setSearchTerms]
  );

  const handleCountryCodeChange = useCallback(
    (option) => {
      setSearchTerms({ ...searchTerms, countryCode: option });
    },
    [setSearchTerms, searchTerms]
  );

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();

      const trimmedTerms = trimSearchTerms(searchTerms);

      const mergeCriterias = {
        ...trimmedTerms,
        ...{ partnerIds: parnterIdsSelected },
      };

      setSearchTerms(mergeCriterias);

      dispatch({ type: 'updateSearchTerms', args: mergeCriterias });
      if (setSelectedRowKeys) {
        setSelectedRowKeys([]);
      }
    },
    [
      dispatch,
      searchTerms,
      setSearchTerms,
      setSelectedRowKeys,
      parnterIdsSelected,
    ]
  );

  const handleReset = useCallback(() => {
    setSearchTerms(CLEARED_STATE);
    if (setSelectedRowKeys) {
      setSelectedRowKeys([]);
    }

    dispatch({ type: 'updateSearchTerms', args: CLEARED_STATE });
  }, [setSearchTerms, dispatch, CLEARED_STATE, setSelectedRowKeys]);

  return (
    <Stack component="form" onSubmit={handleSubmit} rowGap="0.75rem">
      <Text variant="titleM">{label}</Text>
      <StyledRow>
        {fields.map((field: IField) => {
          if (field.key === 'countryCode') {
            return (
              <SearchWrapper key={field.key}>
                <CountrySelector
                  name="country"
                  onChange={handleCountryCodeChange}
                  countryCodeValue={searchTerms[field.key]}
                  placeholder="Country"
                  width="100%"
                />
              </SearchWrapper>
            );
          }
          if (field.key === 'partnerIds') {
            return (
              <SearchWrapper key={field.key}>
                <PartnerAutocomplete
                  placeholder={field.placeholder}
                  style={{ width: '250px', marginRight: '15px' }}
                  uptdateSearch={setParnterIdsSelected}
                />
              </SearchWrapper>
            );
          }

          return (
            <SearchWrapper key={field.key}>
              <SearchField
                key={field.key}
                value={searchTerms[field.key]}
                name={field.key}
                placeholder={field.placeholder}
                label={field.label}
                handleChange={handleChange}
                width="100%"
              />
            </SearchWrapper>
          );
        })}
        <ButtonWrapper>
          <SearchButton />
        </ButtonWrapper>
        <ButtonWrapper>
          <SearchResetButton handleReset={handleReset} />
        </ButtonWrapper>
      </StyledRow>
    </Stack>
  );
};

export default ServerSearch;
