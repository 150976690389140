import React, { useState } from 'react';

import { Box, Text } from '@lp/ds-next';
import { Form, Button } from 'antd';
import styled from 'styled-components';

import { getRows } from './commonFunctions/getRows';
import useAddSocialMediaData from './hooks/useAddSocialMediaData';
import { socialMediaArticle } from '../../features/dashboard/models/socialMediaArticle';

const StyledTable = styled.table`
  width: 100%;
  margin-bottom: 0.5rem;
`;

const StyledForm = styled(Form)`
  & .ant-input-number.ant-input-number-in-form-item {
    width: 100%;
  }
  & .ant-picker {
    width: 100%;
  }
`;

const StyledTh = styled.th`
  padding-left: 0.9rem;
  text-align: left;
`;

const COLUMNS = [
  { key: 'description', text: 'Description', language: 'english' },
  { key: 'publicated_at', text: 'Date of event', language: 'english' },
  { key: 'title', text: 'Title Text', language: 'english' },
  { key: 'url', text: 'Title Link', language: 'english' },
  { key: 'url_icon', text: 'Icon Image URL', language: 'english' },
  { key: 'description_fr', text: 'Description', language: 'french' },
  { key: 'title_fr', text: 'Titre du texte', language: 'french' },
  { key: 'url_fr', text: 'Titre du lien', language: 'french' },
  { key: 'description_de', text: 'Bezeichnung', language: 'german' },
  { key: 'title_de', text: 'Texttitel', language: 'german' },
  { key: 'url_de', text: 'Title Link', language: 'german' },
];

const INITIALVALUES: socialMediaArticle = {
  title: '',
  description: '',
  publicated_at: '',
  url: '',
  title_fr: '',
  description_fr: '',
  url_fr: '',
  title_de: '',
  description_de: '',
  url_de: '',
  url_icon: '',
};

const SocialMediaTable = () => {
  const initialArticles: socialMediaArticle[] = Array(5).fill(INITIALVALUES);
  const [step, setStep] = useState(1);
  const [formData, setFormData] =
    useState<socialMediaArticle[]>(initialArticles);
  const [{ loading }, addSocialMediaData] = useAddSocialMediaData();

  const prevStep = () => {
    setStep(step - 1);
  };

  const onFinish = async (values) => {
    if (step !== 3) {
      setStep(step + 1);
    }

    const res = Object.entries(values).reduce((o, [k, v]) => {
      const [name, number]: any = k.match(/\D+|\d+$/g);
      o[number] = { ...(o[number] || {}), [name]: v };

      return o;
    }, {});

    const data: socialMediaArticle[] = Object.values(res);
    setFormData((prevData) =>
      prevData.map((obj, index) => ({ ...obj, ...data[index] }))
    );

    const newData = formData.map((obj, index) => ({ ...obj, ...data[index] }));
    if (!newData.some((item) => Object.values(item).includes(''))) {
      addSocialMediaData(newData);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '85%',
        gap: '2rem',
      }}
    >
      <Text variant="titleXL" pl="0.7rem">
        Social Media Table
      </Text>
      <StyledForm layout="inline" name="control-hooks" onFinish={onFinish}>
        <StyledTable>
          <tr>
            {COLUMNS.map((col, index) => {
              if (step === 1 && col.language === 'english') {
                return <StyledTh key={index}>{col.text}</StyledTh>;
              }
              if (step === 2 && col.language === 'french') {
                return <StyledTh key={index}>{col.text}</StyledTh>;
              }
              if (step === 3 && col.language === 'german') {
                return <StyledTh key={index}>{col.text}</StyledTh>;
              }

              return null;
            })}
          </tr>
          <tbody>{getRows(step, COLUMNS)}</tbody>
        </StyledTable>

        {step === 1 && (
          <Form.Item style={{ margin: '1rem 1rem' }}>
            <Button loading={loading} type="primary" htmlType="submit">
              Next
            </Button>
          </Form.Item>
        )}
        {step === 2 && (
          <>
            <Form.Item style={{ margin: '1rem 1rem' }}>
              <Button
                onClick={prevStep}
                loading={loading}
                type="primary"
                htmlType="submit"
              >
                Prev
              </Button>
            </Form.Item>
            <Form.Item style={{ margin: '1rem 1rem' }}>
              <Button loading={loading} type="primary" htmlType="submit">
                Next
              </Button>
            </Form.Item>
          </>
        )}
        {step === 3 && (
          <>
            <Form.Item style={{ margin: '1rem 1rem' }}>
              <Button
                onClick={prevStep}
                loading={loading}
                type="primary"
                htmlType="submit"
              >
                Prev
              </Button>
            </Form.Item>
            <Form.Item style={{ margin: '1rem 1rem' }}>
              <Button loading={loading} type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </>
        )}
      </StyledForm>
    </Box>
  );
};

export default SocialMediaTable;
