import React, { useCallback, useEffect, useState } from 'react';

import { ReloadOutlined } from '@ant-design/icons';
import { Box, HStack, VStack } from '@chakra-ui/react';
import { Grid } from '@lp/ds-next';
import { isEmpty } from 'lodash/fp';

import ProductsTable from '../components/ProductsTable';
import useBoxesStore from '../hooks/useBoxesStore';
import PageTitle from '@/components/layout/PageTitle';
import PillButton from '@/components/PillButton';
import ServerSearch from '@/components/ServerSearch';
import { scopes } from '@/config';
import useAppState from '@/hooks/useAppState';
import ProductTotalProvider from '@/shared/providers/productTotalProvider';

const ProductsListView = () => {
  const userScopes = useAppState((state) => state.scopes);
  const searchTerms = useBoxesStore((state) => state.searchTerms);
  const dispatch = useBoxesStore((state) => state.dispatch);
  const [showMultiEditModal, setShowMultiEditModal] = useState<boolean>(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  useEffect(() => () => dispatch({ type: 'reset' }), [dispatch]);

  const handleRefresh = useCallback(() => {
    dispatch({
      type: 'refreshTable',
    });
  }, [dispatch]);

  return (
    <Grid container justifyContent="center" spacing={3}>
      <Grid item mobile={11}>
        <PageTitle title="PRODUCTS" />
      </Grid>
      <Grid item mobile={11}>
        <HStack>
          <ServerSearch
            fields={[
              { key: 'thingName', placeholder: 'LPUI' },
              {
                key: 'partnerIds',
                placeholder: 'Partnership Name',
              },
              { key: 'holderId', placeholder: 'Holder: Shop-ID or Account-ID' },
              { key: 'bleAddress', placeholder: 'BleMacAddress' },
              { key: 'notesSearchTerm', placeholder: 'Notes search' },
            ]}
            dispatch={dispatch}
            storeSearchTerms={searchTerms}
            setSelectedRowKeys={setSelectedRowKeys}
          />
          <VStack justify="space-around">
            <Box h="5px"></Box>
            {userScopes.includes(scopes.REGISTER_BOX) && (
              <PillButton
                data-testid="multi-edit-button"
                type="default"
                disabled={isEmpty(selectedRowKeys)}
                onClick={() => setShowMultiEditModal(true)}
              >
                Multi Edit
              </PillButton>
            )}
          </VStack>
          <VStack justify="space-around">
            <Box h="5px"></Box>
            <PillButton
              style={{ marginBottom: '1rem' }}
              onClick={handleRefresh}
              type="default"
              icon={<ReloadOutlined />}
              data-testid="refresh-button"
            ></PillButton>
          </VStack>
        </HStack>
        <ProductTotalProvider>
          <ProductsTable
            showModal={showMultiEditModal}
            setShowModal={setShowMultiEditModal}
            setSelectedRowKeys={setSelectedRowKeys}
            selectedRowKeys={selectedRowKeys}
          />
        </ProductTotalProvider>
      </Grid>
    </Grid>
  );
};

export default ProductsListView;
