import React, { useState } from 'react';

import { Circle, InfoWindow, Marker } from '@react-google-maps/api';
import { theme } from 'antd';

import { GMInfoWindowContent } from './GMInfoWindowContent';
import { GPS_ACQUIRE } from '../../hooks/useShipmentMapEvents';
import { IBoxEvent } from '../../models/shipment';
import GpsImg from '@/assets/images/gps.png';
import LocationImg from '@/assets/images/location.png';
import dates from '@/helpers/dates';

const { useToken } = theme;

const GeolocationMarker = ({
  event,
  clusterer,
}: {
  event: IBoxEvent;
  clusterer: any;
}) => {
  const [infoWindow, setInfoWindow] = useState(false);
  const { token } = useToken();

  return (
    <>
      <Marker
        position={{
          lat: event.coordinate.latitude,
          lng: event.coordinate.longitude,
        }}
        icon={event.type === GPS_ACQUIRE ? GpsImg : LocationImg}
        onMouseOver={() => setInfoWindow(true)}
        onMouseOut={() => setInfoWindow(false)}
        clusterer={clusterer}
      >
        {infoWindow && (
          <InfoWindow options={{ disableAutoPan: true }}>
            <GMInfoWindowContent
              header={event.type}
              content={
                event.timestamp
                  ? dates.parseDate(event.timestamp)
                  : 'Date unknown'
              }
            />
          </InfoWindow>
        )}
      </Marker>
      <Circle
        radius={event.coordinate.accuracy}
        options={{
          strokeColor: token.colorPrimary,
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: token.colorPrimary,
          fillOpacity: 0.35,
        }}
        center={{
          lat: event.coordinate.latitude,
          lng: event.coordinate.longitude,
        }}
      />
    </>
  );
};

export default GeolocationMarker;
