import React from 'react';

import { CheckOutlined, CopyOutlined, SelectOutlined } from '@ant-design/icons';
import { Stack } from '@lp/ds-next';
import { Popover, Button } from 'antd';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';

import EyeLink from '@/components/EyeLink';
import BilledIcon from '@/components/icons/BilledIcon';
import DeleteIcon from '@/components/icons/DeleteIcon';
import DollarCrossedIcon from '@/components/icons/DollarCrossedIcon';
import DollarIcon from '@/components/icons/DollarIcon';
import EditIcon from '@/components/icons/EditIcon';
import PaidIcon from '@/components/icons/PaidIcon';
import PlusIcon from '@/components/icons/PlusIcon';
import InlineSpacer from '@/components/layout/InlineSpacer';
import RecipientSender from '@/components/RecipientSender';
import ScopedCta from '@/components/ScopedCta';
import IdCell from '@/components/tables/IdCell';
import { COUNTRY_CODES_JSON_PATH } from '@/config';
import { theme } from '@/config';
import { getDefaultSortOrder } from '@/helpers/antdTable';
import { ColumnTitle, isId } from '@/helpers/columnTitles';
import dates, { formatDate, formatISODate } from '@/helpers/dates';
import useCountryCodes from '@/shared/hooks/useCountryCodes';

const useAntdColumns = <
  T extends {} = { [key: string]: any },
  AdditionalKeys = void,
>({
  columnsKeys,
  columnsSpecialProps = {},
  addDefaultColumns,
  defaultSort,
  eyeLinkProps,
  deleteIconProps,
  addIconProps,
  editIconProps,
  cloneIconProps,
  externalLinkProps,
}: {
  columnsKeys: (keyof T | AdditionalKeys)[];
  columnsSpecialProps?: any;
  addDefaultColumns?: boolean;
  defaultSort?: any;
  eyeLinkProps?: {
    to?: string;
    onClick?: any;
    rest?: any;
    filters?: any;
    filteredValue?: any;
    filterMultiple?: any;
    key?: any;
    dataIndex?: any;
    store?: any;
    queryParam?: string;
    width?: string;
  };
  deleteIconProps?: {
    onClick?: any;
    requiredScopes?: string[];
    isPopover?: boolean;
    rest?: any;
    customRender?: (record) => React.ReactNode;
  };
  addIconProps?: any;
  editIconProps?: any;
  cloneIconProps?: any;
  externalLinkProps?: {
    onClick?: any;
    customRender?: (record) => React.ReactNode;
  };
}) => {
  const [, getCountryNameFromCode] = useCountryCodes({
    localCountryCodesPath: COUNTRY_CODES_JSON_PATH,
  });
  const dateColumn = (type: 'createdAt' | 'updatedAt') => {
    const sort = defaultSort
      ? {
          sorter: true,
          defaultSortOrder: getDefaultSortOrder(defaultSort, type),
        }
      : { sorter: false };

    return {
      title: ColumnTitle[type],
      dataIndex: type,
      key: type,
      render: (_text, record) => {
        if (record.updatedAt instanceof Date) {
          return formatDate(record[type]);
        } else if (moment(record[type], moment.ISO_8601, true).isValid()) {
          return formatISODate(record[type]);
        } else if (
          record.hasOwnProperty('parsedCreatedAt') ||
          record.hasOwnProperty('parsedUpdatedAt')
        ) {
          return record[
            type === 'createdAt' ? 'parsedCreatedAt' : 'parsedUpdatedAt'
          ];
        }

        return dates.parseDate(record[type]);
      },
      width: '7rem',
      ...sort,
      ...columnsSpecialProps[type],
    };
  };

  const getContractState = (record) => {
    let contractState: string = record.contractState;
    if (record.isArchived) {
      contractState = 'ARCHIVED';
    }
    if (record.deletedAt > 0) {
      contractState = 'DELETED';
    }

    return contractState;
  };
  const getBillingStatusIcon = (record) => {
    const content = record.isBillable ? (
      record.isBilled && !record.isPaid ? (
        <BilledIcon />
      ) : record.isBilled && record.isPaid ? (
        <PaidIcon />
      ) : (
        <DollarIcon />
      )
    ) : (
      <DollarCrossedIcon />
    );

    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>{content}</div>
    );
  };
  let columns = columnsKeys.map((col, i) => {
    switch (col) {
      case isId(col):
        return {
          title: ColumnTitle[col as string],
          key: col,
          dataIndex: col,
          fixed: i === 0 ? 'left' : false,
          width: '7rem',
          render: (_text, record) => {
            const id = get(record, col as string, '') || get(record, 'id', '');

            return (
              <div data-testid={col} data-testvalue={id}>
                <IdCell id={id} />
              </div>
            );
          },
          ...columnsSpecialProps[col],
        };
      case 'thingName':
      case 'lp_ui':
        return {
          title: ColumnTitle[col as string],
          key: col,
          dataIndex: col,
          fixed: i === 0 ? 'left' : false,
          width: '7rem',
          render: (_text, record) => {
            const id = record.thingName || record.lp_ui;

            return (
              <div data-testid={col} data-testvalue={id}>
                <IdCell id={id} full={true} />
              </div>
            );
          },
          ...columnsSpecialProps[col],
        };
      case 'countryCode':
        return {
          title: ColumnTitle[col as string],
          dataIndex: 'countryCode',
          key: col,
          render: (_text, record) => getCountryNameFromCode(record.countryCode),
          width: '8rem',
        };
      case 'position':
        return {
          title: ColumnTitle[col as string],
          dataIndex: col,
          key: col,
          render: (_text, record) =>
            !isEmpty(record.position)
              ? `(${record.position.latitude},${record.position.longitude})`
              : '',
          width: '8rem',
        };
      case 'recipientAndSender':
        return {
          title: ColumnTitle[col as string],
          dataIndex: col,
          key: col,
          render: (_text, record) => (
            <RecipientSender
              recipient={record.recipientSender[0]}
              sender={record.recipientSender[1]}
            />
          ),
          width: '10rem',
        };
      case 'contractState':
        return {
          title: ColumnTitle[col as string],
          dataIndex: col,
          key: col,
          render: (_text, record) => getContractState(record),
          width: '4rem',
          ...columnsSpecialProps[col],
        };
      case 'notes':
        return {
          title: ColumnTitle[col as string],
          dataIndex: col,
          key: col,
          render: (text) =>
            text && (
              <div style={{ textAlign: 'center' }}>
                <CheckOutlined />
              </div>
            ),
          width: '4rem',
        };
      case 'createdAt':
        return dateColumn('createdAt');
      case 'updatedAt':
        return dateColumn('updatedAt');
      case 'boxSerialNumber':
        return {
          title: ColumnTitle['thingName' as string] || col,
          dataIndex: col,
          key: col,
          width: '7rem',
          render: (_text, record) => {
            const id = record.box.thingName;

            return (
              <div data-testid={col} data-testvalue={id}>
                <IdCell id={id} />
              </div>
            );
          },
        };
      case 'villageServiceLevel':
        return {
          title: ColumnTitle['serviceLevel' as string] || col,
          dataIndex: col,
          key: col,
          width: '5rem',
          ...columnsSpecialProps[col],
          render: (_text, record) => record?.serviceLevel?.name,
        };
      case 'fieldName':
      case 'before':
      case 'after':
        return {
          title: ColumnTitle[col as string],
          dataIndex: col,
          key: col,
          width: '8rem',
          render: (_text, record) =>
            record.payload != null
              ? record.payload.map((oneRecord) => oneRecord[col]).join(', ')
              : '',
        };
      case 'changelogItemId':
      case 'createdByID':
        return {
          title: ColumnTitle[col as string],
          dataIndex: col,
          key: col,
          width: '7rem',
          render: (_text, record) => {
            const id = record[col];

            return (
              <div data-testid={col} data-testvalue={id}>
                <IdCell id={id} />
              </div>
            );
          },
        };
      case 'createdByType':
      case 'changelogItemType':
        return {
          title: ColumnTitle[col as string],
          dataIndex: col,
          key: col,
          width: '10rem',
          ...columnsSpecialProps[col],
          render: (_text, record) => record[col],
        };
      case 'reference':
        return {
          title: 'Shipment name',
          dataIndex: col,
          key: col,
          width: '10rem',
          ...columnsSpecialProps[col],
        };
      case 'partnerName':
        return {
          title: ColumnTitle[col as string],
          dataIndex: col,
          key: col,
          width: '10rem',
          ...columnsSpecialProps[col],
          render: (_text, record) => record.name,
        };
      case 'isBillable':
        return {
          title: ColumnTitle[col as string],
          dataIndex: col,
          key: col,
          render: (_text, record) => getBillingStatusIcon(record),
          width: '3rem',
        };
      case 'addressTypes':
        return {
          title: ColumnTitle[col as string],
          dataIndex: col,
          key: col,
          render: (_text, record) =>
            record?.addressTypes ? record?.addressTypes.join(', ') : '',
          width: '5rem',
        };
      default:
        return {
          title: ColumnTitle[col as string] || col,
          dataIndex: col,
          key: col,
          width: '5rem',
          ...columnsSpecialProps[col],
        };
    }
  });

  if (addDefaultColumns) {
    columns = [
      ...columns.slice(0, 1),
      dateColumn('createdAt'),
      dateColumn('updatedAt'),
      ...columns.slice(1),
    ];
  }
  if (eyeLinkProps) {
    const filterProvided = !!eyeLinkProps.filters;
    const KeyProvided = !!eyeLinkProps.key;
    const filteredValueProvided = !!eyeLinkProps.filteredValue;

    columns = [
      ...columns,
      {
        key:
          KeyProvided && eyeLinkProps.key === 'accountVerified'
            ? 'accountVerified'
            : 'eye',
        filters: filterProvided ? eyeLinkProps.filters : null,
        filterMultiple: false,
        filteredValue: filteredValueProvided
          ? eyeLinkProps.filteredValue
          : null,
        onCell: (record) => ({
          style: {
            position: 'relative',
            backgroundColor:
              record.accountVerified === true
                ? theme.colors.green
                : theme.colors.white,
          },
        }),
        render: (_text, record) => {
          const onClickProvided = !!eyeLinkProps.onClick;

          return onClickProvided ? (
            <EyeLink
              onClick={() =>
                onClickProvided ? eyeLinkProps.onClick(record) : {}
              }
            />
          ) : (
            <EyeLink
              to={`${eyeLinkProps.to}/${
                eyeLinkProps.queryParam
                  ? record[eyeLinkProps.queryParam]
                  : record.id
              }`}
            />
          );
        },
        ...eyeLinkProps.rest,
        width: eyeLinkProps.width || '3rem',
        fixed: columnsKeys.length > 7 ? 'right' : false,
      },
    ];
  }

  if (deleteIconProps) {
    columns = [
      ...columns,
      {
        title: '',
        key: 'delete',
        render: (_text, record) => (
          <Stack
            justifyContent="center"
            alignItems="center"
            position="absolute"
            top="0px"
            left="0px"
            width="100%"
            height="100%"
            sx={{
              pointerEvents: record?.addressTypes?.includes('MAIN')
                ? 'none'
                : 'auto',
              opacity: record?.addressTypes?.includes('MAIN') ? '0.3' : '1',
            }}
            data-testid="delete-address-icon"
          >
            {deleteIconProps?.isPopover ? (
              <Popover
                trigger="click"
                title="Delete entry?"
                content={
                  <ScopedCta
                    component={Button}
                    onClick={(e) => deleteIconProps.onClick(e, record)}
                    type="link"
                    icon={<DeleteIcon />}
                    requiredScopes={[deleteIconProps?.requiredScopes]}
                  >
                    <InlineSpacer width="0.5rem" /> Confirm
                  </ScopedCta>
                }
              >
                <DeleteIcon />
              </Popover>
            ) : deleteIconProps?.customRender ? (
              deleteIconProps.customRender(record)
            ) : (
              <DeleteIcon onClick={() => deleteIconProps.onClick(record)} />
            )}
          </Stack>
        ),
        width: '3rem',
        ...deleteIconProps.rest,
        fixed: columnsKeys.length > 7 ? 'right' : false,
      },
    ];
  }

  if (editIconProps) {
    columns = [
      ...columns,
      {
        title: editIconProps.title || '',
        key: 'edit',
        render: (_text, record) => (
          <ScopedCta
            component={EditIcon}
            onClick={() => editIconProps.onClick(record)}
            requiredScopes={editIconProps?.requiredScopes}
          />
        ),
        width: '3rem',
      },
    ];
  }

  if (addIconProps) {
    columns = [
      ...columns,
      {
        title: addIconProps.title || '',
        key: 'add',
        render: (_text, record) => (
          <ScopedCta
            component={PlusIcon}
            onClick={() => addIconProps.onClick(record)}
            requiredScopes={[addIconProps?.requiredScopes]}
          />
        ),
        width: '4rem',
      },
    ];
  }

  if (cloneIconProps) {
    columns = [
      ...columns,
      {
        title: cloneIconProps.title || '',
        key: 'add',
        render: (_text, record) => (
          <ScopedCta
            component={CopyOutlined}
            onClick={() => cloneIconProps.onClick(record)}
            requiredScopes={cloneIconProps?.requiredScopes}
          />
        ),
        width: '2rem',
      },
    ];
  }

  if (externalLinkProps) {
    columns = [
      ...columns,
      {
        key: 'external-link',
        render: (_text, record) => {
          if (externalLinkProps?.customRender) {
            return externalLinkProps.customRender(record);
          }

          return (
            <SelectOutlined
              onClick={() => externalLinkProps.onClick(record.id)}
            />
          );
        },
        width: '2rem',
      },
    ];
  }

  return columns;
};

export default useAntdColumns;
