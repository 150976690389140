import { useMutation } from '@tanstack/react-query';

import { Address } from '../types/address';
import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { IAddToPartnerAddress } from '@/models/address';
import useToasts from '@/shared/hooks/useToasts';
import { MessageType } from '@/shared/hooks/useToasts.types';

export const createPartnerAddress =
  (makeRequest: any) =>
  async ({
    partnerId,
    values,
  }: {
    partnerId: string;
    values: IAddToPartnerAddress;
  }): Promise<Address> => {
    const { error, data } = await makeRequest({
      path: `/admin/partners/${partnerId}/addresses`,
      method: 'post',
      body: values,
    });
    if (error) {
      throw error;
    }

    return data;
  };

type UseCreatePartnerAddressOptions = {
  partnerId: string;
  config?: MutationConfig<ReturnType<typeof createPartnerAddress>>;
};

export const useCreatePartnerAddresses = ({
  partnerId,
  config,
}: UseCreatePartnerAddressOptions) => {
  const [, makeRequest] = useImperativeRequestWrapper('lpVillage');
  const addError = useError();
  const addSuccessMsg = useToasts((state) => state.addItem);

  return useMutation({
    onError: (error) => {
      if (error.response?.status === 400) {
        if (
          (error.response as any).data.code ===
          'lpvillageBadRequestThisTypeOfAddressAlreadyExists'
        ) {
          addError(`Error  ${(error.response as any).data.message}`);
        } else {
          addError(`Unable to create the partner address, an error occurred.`);
        }
      }
    },
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: ['partner', partnerId, 'addresses'],
      });

      addSuccessMsg(
        {
          msg: 'Address was successfully added!',
          type: MessageType.Success,
        },
        'root',
        {}
      );
    },
    ...config,
    mutationFn: createPartnerAddress(makeRequest),
  });
};
