import { useCallback, useEffect, useMemo, useState } from 'react';

import { debounce } from 'lodash/fp';

import { lpPartnerPostProcessor } from '../helpers/lpPartnersPostProcessor';
import {
  IPartnerV3,
  INITIAL_PartnerV3,
  IPartnerV3Update,
} from '../models/partnerV3';
import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import useToasts from '@/shared/hooks/useToasts';
import { MessageType } from '@/shared/hooks/useToasts.types';

class LpvillageMissingPartnerAddresses extends Error {
  public static readonly code = 'lpvillageMissingPartnerAddresses';

  constructor() {
    super('The Partner is missing a MAIN address.');
  }
}
class LpvillageMissingPartnerCountryCode extends Error {
  public static readonly code = 'lpvillageMissingPartnerCountryCode';

  constructor() {
    super('The Partner country code is missing.');
  }
}

class LpvillageMissingPartnerName extends Error {
  public static readonly code = 'LpvillageMissingPartnerName';

  constructor() {
    super('The Partner name is missing.');
  }
}

export interface ILPPartnerState {
  error: string;
  loading: boolean;
  data: IPartnerV3;
}

const INITIAL_STATE = {
  error: '',
  loading: true,
  data: INITIAL_PartnerV3,
};

export interface PartnerData {
  loading: boolean;
  partnerState: ILPPartnerState;
  setPartner: (res: any) => void;
  updatePartner: (values: IPartnerV3Update) => Promise<{ success: boolean }>;
  refetchPartner: () => Promise<void>;
}

const useLPPartner: (id: string) => PartnerData = (id) => {
  const [partner, setPartner] = useState<ILPPartnerState>(INITIAL_STATE);

  const [{ loading }, makeRequest] = useImperativeRequestWrapper('lpVillageV3');
  const addError = useError();
  const addSuccessMsg = useToasts((state) => state.addItem);

  const debounceAddSuccessMsg = useMemo(
    () => debounce(500, addSuccessMsg),
    [addSuccessMsg]
  );

  const setPartnerWithProcessing = useCallback(
    async (data: any) => {
      try {
        const withDates = lpPartnerPostProcessor(data);
        setPartner((prev) => ({
          ...prev,
          loading: false,
          data: withDates,
        }));
      } catch (err: any) {
        setPartner((prev) => ({
          ...prev,
          loading: false,
          error: err.toString(),
        }));
        addError(err);
      }
    },
    [setPartner, addError]
  );

  const getPartner = useCallback(async () => {
    const { error, data } = await makeRequest({
      path: `/partners/${id}`,
      method: 'get',
    });

    if (!!error) {
      setPartner((prev) => ({ ...prev, loading: false, error }));
      addError(error);

      return;
    }

    await setPartnerWithProcessing(data.partner);
  }, [makeRequest, addError, id, setPartnerWithProcessing]);

  const updatePartner = useCallback(
    async (values: IPartnerV3Update) => {
      const { error, data } = await makeRequest({
        path: `/partners/${id}`,
        method: 'patch',
        body: values,
      });

      if (error) {
        setPartner((prev) => ({ ...prev, loading: false, error: error }));

        if (
          error.response.data.message.includes(
            LpvillageMissingPartnerAddresses.code
          )
        ) {
          addError(new LpvillageMissingPartnerAddresses().toString());
        } else if (
          error.response.data.message.includes(
            LpvillageMissingPartnerCountryCode.code
          )
        ) {
          addError(new LpvillageMissingPartnerCountryCode().toString());
        } else if (
          error.response.data.message.includes(LpvillageMissingPartnerName.code)
        ) {
          addError(new LpvillageMissingPartnerName().toString());
        } else {
          // Other errors
          addError(error);
        }

        return { success: false };
      }

      await setPartnerWithProcessing(data.partner);

      // Debounce success message to avoid too many notifications
      // in case of multiple close updates
      debounceAddSuccessMsg(
        { msg: 'Partner was successfully updated!', type: MessageType.Success },
        'root'
      );

      return { success: true };
    },
    [addError, id, makeRequest, setPartnerWithProcessing, debounceAddSuccessMsg]
  );

  useEffect(() => {
    if (!id) return;
    getPartner();
  }, [getPartner, id]);

  return {
    loading,
    partnerState: partner,
    setPartner: setPartnerWithProcessing,
    updatePartner,
    refetchPartner: getPartner,
  };
};

export default useLPPartner;
