import { useMutation } from '@tanstack/react-query';

import { ITrackingCode } from '../../types/trackingCode';
import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import { MutationConfig, queryClient } from '@/lib/react-query';
import useToasts from '@/shared/hooks/useToasts';
import { MessageType } from '@/shared/hooks/useToasts.types';

export const revokeTrackingCode =
  (makeRequest: any) =>
  async ({
    trackingCode,
  }: {
    trackingCode: string;
  }): Promise<any | ITrackingCode> => {
    const { data, error } = await makeRequest({
      path: `/admin/tracking-codes/${trackingCode}`,
      method: 'patch',
      body: { isRevoked: true },
    });

    if (!data || error) {
      throw new Error(error);
    }

    return data;
  };

type UseRevokeTrackingCodeOptions = {
  shipmentId: string;
  config?: MutationConfig<ReturnType<typeof revokeTrackingCode>>;
};

export const useRevokeTrackingCode = ({
  shipmentId,
  config,
}: UseRevokeTrackingCodeOptions) => {
  const addError = useError();
  const addSuccessMsg = useToasts((state) => state.addItem);
  const [, makeRequest] = useImperativeRequestWrapper('shipment');

  return useMutation({
    onError: (error) => {
      addError(error);
    },
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: ['shipment', shipmentId, 'trackingCode'],
      });

      addSuccessMsg(
        {
          msg: 'The delivery key was revoked successfully',
          type: MessageType.Success,
        },
        'root',
        {}
      );
    },
    ...config,
    mutationFn: revokeTrackingCode(makeRequest),
  });
};
