import React, { useCallback } from 'react';

import { CloseOutlined } from '@ant-design/icons';
import { Grid } from '@lp/ds-next';

import Label from '@/components/forms/Label';
import TextInput from '@/components/forms/TextInput';
import PillButton from '@/components/PillButton';
import useInputFocus from '@/hooks/useInputFocus';

interface ISearchObjectForm {
  handleFind: (searchField: string) => void;
  disabled?: boolean;
  searchField: string;
  setSearchField: (searchField: string) => void;
  objectId?: string;
  setObjectId: (id: string) => void;
  setSearchResults?: (Array) => void;
  searchLabel: string;
  foundLabel: string;
  placeholder?: string;
}

/**
 * A search field component !!not generic!!
 *
 * @param {callback}  handleFind call back to parent's result handling function
 * @param {boolean}  disabled used to lock the find button
 * @param {string}  objectId reference to parents hook value
 * @param {string}  searchField reference to parents hook value
 * @param {any}  setSearchField reference to parents hook setter functon
 * @param {any}  setObjectId reference to parents hook setter functon
 * @param {string}  searchLabel the Title/Label shown over the serach field
 * @param {string}  foundLabel the Title/Label shown over the serach field after a result has been found
 * @param {string}  setSearchResults reference to parents hook setter functon
 * @param {string}  placeholder the placeholder shown in the input
 * @returns
 */
const SearchObject = ({
  handleFind,
  disabled,
  objectId,
  searchField,
  setSearchField,
  setObjectId,
  searchLabel,
  foundLabel,
  setSearchResults = () => {},
  placeholder = 'Search...',
}: ISearchObjectForm) => {
  const [ref] = useInputFocus({ initialFocus: true });

  const handleInputChange = useCallback(
    (e) => {
      setObjectId('');
      setSearchField(e.target.value);
    },
    [setObjectId, setSearchField]
  );

  const handleReset = useCallback(() => {
    setObjectId('');
    setSearchField('');
    setSearchResults([]);
  }, [setObjectId, setSearchField, setSearchResults]);

  return (
    <>
      {!objectId ? (
        <Grid container spacing={2}>
          <Grid item mobile={12}>
            <Label>{searchLabel}</Label>
          </Grid>
          <Grid item mobile={8}>
            <TextInput
              ref={ref}
              onChange={handleInputChange}
              value={searchField}
              name="searchField"
              placeholder={placeholder}
              disabled={disabled}
            />
          </Grid>
          <Grid item mobile={4}>
            <PillButton
              onClick={() => handleFind(searchField)}
              disabled={disabled}
            >
              Find
            </PillButton>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2}>
          <Grid item mobile={12}>
            <Label>{foundLabel}</Label>
          </Grid>
          <Grid item mobile={8}>
            <TextInput disabled name="objectSearchField" value={searchField} />
          </Grid>
          <Grid item mobile={4}>
            <CloseOutlined style={{ padding: '1rem' }} onClick={handleReset} />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default SearchObject;
