import React, { forwardRef } from 'react';

import {
  NewTooltip as Tooltip,
  Text,
  Box,
  ButtonV2 as Button,
  DeleteIcon,
} from '@lp/ds-next';
import { Typography } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { useParams } from 'react-router-dom';

import { useTrackingCodes } from '../api/trackingCode/getTrackingCodes';
import { useRevokeTrackingCode } from '../api/trackingCode/revokeTrackingCode';
import { ITrackingCode } from '../types/trackingCode';
import EyeLink from '@/components/EyeLink';
import ActiveIcon from '@/components/icons/ActiveIcon';
import InActiveIcon from '@/components/icons/InActiveIcon';
import IdCell from '@/components/tables/IdCell';
import StyledTable from '@/components/tables/StyledTable';
import useAntdColumns from '@/hooks/useAntdColumns';

const roles = ['Invalid', 'Additional tracker', 'Admin', 'Sender', 'Recipient'];

const columnsKeys = [
  'trackingCodeId',
  'email',
  'phoneNumber',
  'accountId',
  'role',
  'source',
  'isRevoked',
  'createdAt',
  'updatedAt',
];

const TrackingCodesTable = forwardRef<
  HTMLDivElement,
  {
    canViewDeliveryKey: boolean;
  }
>(({ canViewDeliveryKey }, ref) => {
  const { id } = useParams<{ id: string }>();

  const { data: trackingCodes, refetch: refetchTrackingcodes } =
    useTrackingCodes({
      shipmentId: id ?? '',
    });

  const { mutateAsync: revokeTrackingCode } = useRevokeTrackingCode({
    shipmentId: id ?? '',
  });

  const handleRevoke = async (trackingCode: string) => {
    await revokeTrackingCode({ trackingCode });
    await refetchTrackingcodes();
  };

  const currentDate = Date.now() / 1000;

  const columns: ColumnProps<any>[] = useAntdColumns({
    columnsKeys,
    columnsSpecialProps: {
      trackingCodeId: {
        width: '6rem',
        render: (_, record: ITrackingCode) =>
          canViewDeliveryKey ? (
            <Button
              variant="links"
              onClick={() => {
                window.open(
                  `${process.env.REACT_APP_PUBLIC_URL_WEBAPP}shipments/${record.id}/anonymous-detail`
                );
              }}
            >
              <Typography.Text
                copyable
                style={{
                  flexWrap: 'nowrap',
                  display: 'flex',
                  wordBreak: 'unset',
                }}
              >
                {record.id}
              </Typography.Text>
            </Button>
          ) : (
            <Text
              variant="titleS"
              color="custom.neutral.black.30"
              sx={{
                fontStyle: 'italic',
              }}
            >
              Lack of permissions
            </Text>
          ),
      },
      accountId: {
        render: (_, record: ITrackingCode) =>
          record.accountId ? <IdCell id={record.accountId} /> : '-',
      },
      source: {
        render: (_, record: ITrackingCode) =>
          record.sourceAuthApp?.clientName || '-',
      },
      isRevoked: {
        render: (_, record: ITrackingCode) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
          >
            {record.revokedAt! < currentDate ? (
              <InActiveIcon />
            ) : (
              <ActiveIcon />
            )}
          </Box>
        ),
        align: 'center',
      },
      role: {
        render: (roleId: any) => roles[roleId],
      },
      createdAt: { sorter: false },
      updatedAt: { sorter: false },
    },
    eyeLinkProps: {
      rest: {
        render: (_, record: ITrackingCode) =>
          record.accountId ? (
            <Tooltip
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, 10],
                      },
                    },
                  ],
                },
              }}
              title={<Text variant="bodyTextM">Go to LP Account</Text>}
            >
              <EyeLink
                to={`/lp-account/${record.accountId}`}
                data-testid="LPAccount-link"
              />
            </Tooltip>
          ) : (
            '-'
          ),
      },
    },
    addDefaultColumns: false,
    defaultSort: {
      sortBy: 'updatedAt',
      order: 'ASC',
    },
    deleteIconProps: {
      rest: {
        filters: [{ text: 'Active Only', value: false }],
        onFilter: (value, record: ITrackingCode) =>
          value === record?.revokedAt! < currentDate,
        render: (_, record: ITrackingCode) => {
          if (record?.revokedAt! < currentDate) {
            return null;
          }

          return (
            <Tooltip
              data-testid="revoke-link"
              title={
                <Text variant="bodyTextM">
                  {canViewDeliveryKey
                    ? 'Revoke Delivery Key credentials'
                    : 'Lack of permissions'}
                </Text>
              }
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, 10],
                      },
                    },
                  ],
                },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  cursor: 'pointer',
                  opacity: canViewDeliveryKey ? 1 : 0.3,
                }}
              >
                <DeleteIcon
                  onClick={() => canViewDeliveryKey && handleRevoke(record.id!)}
                />
              </Box>
            </Tooltip>
          );
        },
      },
    },
  });

  return (
    <StyledTable
      data-testid="shipment-tracking-codes"
      loading={false}
      dataSource={trackingCodes}
      size="small"
      rowKey="id"
      columns={columns}
      pagination={false}
      ref={ref}
    />
  );
});

TrackingCodesTable.displayName = 'TrackingCodesTable';

export default TrackingCodesTable;
