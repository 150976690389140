import React from 'react';

import { Grid } from '@lp/ds-next';

import TosForm from '../components/TosForm';
import PageTitle from '@/components/layout/PageTitle';

const TosDetailView = () => (
  <Grid container justifyContent="center" spacing={3}>
    <Grid item mobile={11}>
      <PageTitle title="TOS DETAILS" />
    </Grid>
    <Grid item mobile={11}>
      <TosForm />
    </Grid>
  </Grid>
);

export default TosDetailView;
