import React, { useContext, useState } from 'react';

import { EllipsisOutlined } from '@ant-design/icons';
import {
  Grid,
  Stack,
  NewTextField as TextField,
  styled,
  Text,
} from '@lp/ds-next';
import { Card, CardContent } from '@mui/material';
import { Dropdown, Row, Button, Alert } from 'antd';
import { Form, Formik } from 'formik';
import { isEqual, pick } from 'lodash/fp';
import { useNavigate } from 'react-router-dom';

import ArchivePartner from './ArchivePartner';
import { ContractInfosCard } from './ContractInfosCard';
import DeletePartner from './DeletePartner';
import AccountManagement from './OtherInformation/AccountManagement';
import OtherInformation from './OtherInformation/OtherInformation';
import PartnerIdCard from './PartnerIdCard/PartnerIdCard';
import Tabs from './Tabs';
import GoToBoxShipmentButton from '../../../boxes/components/GoToBoxShipmentButton';
import { useCreatePartner } from '../api/createPartner';
import { usePartnerAddresses } from '../api/getPartnerAddresses';
import { useSyncWithERP } from '../api/syncWithERP';
import { PartnerData } from '../api/useLPPartner';
import AvatarInfosCard from '../components/AvatarInfosCard';
import {
  ICreatePartnerV3,
  IPartnerV3Update,
  PARTNER_BUSINESS_TYPE,
  PARTNER_BUSINESS_STATUS,
  editablePath,
} from '../models/partnerV3';
import { PartnerUpdateSchema } from '../models/validationSchema';
import { CurrentPartnerContext } from '../views/LPPartnerDetails';
import EditButtonsOnForm from '@/components/EditButtonsOnForm';
import InsuranceState from '@/components/InsuranceState';
import PillButton from '@/components/PillButton';
import H2 from '@/components/text/H2';
import { scopes } from '@/config';
import useGetSingleAccount from '@/features/lp-account-users/hooks/useGetSingleAccount';
import useError from '@/hooks/useError';
import useToasts from '@/shared/hooks/useToasts';
import { MessageType } from '@/shared/hooks/useToasts.types';

const DropdownButton = styled(Button)`
  justify-content: center;
  width: 42px;
  background-color: rgba(56, 161, 165, 0.3);
`;

const DeletedH2 = styled(H2)`
  color: ${(props) => props.theme.colors.adminPanel.error};
`;

const LPPartnerDetailsContent = () => {
  const navigate = useNavigate();

  const addMessage = useToasts((state) => state.addItem);
  const addError = useError();

  const [emailLpAccount, setEmailLpAccount] = useState<string>('');

  const {
    partnerState: partner,
    setPartner,
    updatePartner,
  } = useContext<PartnerData>(CurrentPartnerContext);

  const {
    loading,
    data: { deleted, is_archived, id: partnerId },
  } = partner;

  const { data: partnerAddresses } = usePartnerAddresses({
    partnerId: partnerId,
  });

  const actionItems = {
    items: [
      {
        label: (
          <ArchivePartner
            partner={partner.data}
            setPartner={setPartner}
            loading={loading}
            disabled={is_archived}
          />
        ),
        key: 2,
      },
      {
        label: (
          <DeletePartner
            partner={partner.data}
            loading={loading}
            setPartner={setPartner}
          />
        ),
        key: 3,
      },
    ],
  };

  const initialValues: IPartnerV3Update | ICreatePartnerV3 = pick(
    editablePath,
    partner.data
  );

  const { mutate: createPartner } = useCreatePartner();
  const { mutate: syncWithERP } = useSyncWithERP();
  const getSingleAccount = useGetSingleAccount();

  const handlepAccountInputChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEmailLpAccount(e?.target.value);
  };

  // check if the partner has  more one an address of type main
  const mainAddress =
    partnerAddresses?.items?.filter((address) =>
      address?.addressTypes?.includes('MAIN')
    ) || [];

  const isPartnerIdCardNotCompleted = (loading, values) =>
    !values.partner_id_card?.client_name?.trim() ||
    !values.partner_id_card?.country_code?.trim() ||
    !values.phone_number?.trim() ||
    !mainAddress?.length;

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={PartnerUpdateSchema}
      validate={(values) => {
        const errors: any =
          values?.business_information?.type?.length === 0 ||
          values?.business_information?.status?.length === 0
            ? {
                business_information: {
                  type:
                    values?.business_information?.type?.length === 0
                      ? 'Please select account type'
                      : '',
                  status:
                    values?.business_information?.status?.length === 0
                      ? 'Please select account status'
                      : '',
                },
              }
            : {};

        return errors;
      }}
      onSubmit={async (values: IPartnerV3Update) => {
        // Update Partner
        if (partnerId) {
          const { success } = await updatePartner(
            pick(editablePath, values) as IPartnerV3Update
          );

          // Manually sync with ERP after updating the partner
          if (
            success &&
            partner?.data?.business_information?.type ===
              PARTNER_BUSINESS_TYPE.PRO &&
            partner?.data?.business_information.status ===
              PARTNER_BUSINESS_STATUS.VALIDATED
          ) {
            syncWithERP();
          }
          // Create Partner
        } else {
          createPartner(values, {
            onError: (error: any) => {
              if (
                error?.response?.data?.message ===
                'lpvillagePartnerAlreadyExists'
              ) {
                addError('Partner name already exists');
              } else {
                addError('Unable to create the partner, an error occurred.');
              }
            },
            onSuccess: (resp) => {
              // TODO: Faut il faire une demande de synchro avec l'ERP dans le cas de la création ?
              const { data } = resp;
              if (data && data.partner.id) {
                navigate(`/lp-village/partners/${data.partner.id}`);
              }
            },
          });
        }
      }}
    >
      {({
        isValid,
        isSubmitting,
        resetForm,
        submitForm,
        values,
        setFieldValue,
      }) => (
        <Form>
          <Grid container direction="column" spacing="0.5rem">
            <Grid item mobile={12}>
              {partnerId && (
                <Grid
                  container
                  justifyContent="flex-end"
                  gap={1}
                  // Used to align the buttons with the header
                  // (should move it to the parent later)
                  sx={{
                    marginTop: { mobile: 'auto', laptop: '-74px' },
                    marginBottom: { mobile: 'auto', laptop: '24px' },
                  }}
                >
                  <GoToBoxShipmentButton partnerId={partnerId} />
                  <Dropdown menu={actionItems} trigger={['click']}>
                    <DropdownButton shape="round">
                      <EllipsisOutlined />
                    </DropdownButton>
                  </Dropdown>
                </Grid>
              )}
            </Grid>

            {(deleted || is_archived) && (
              <Grid item mobile={12}>
                {deleted && (
                  <Row>
                    <DeletedH2 data-testid="partner-deleted">DELETED</DeletedH2>
                  </Row>
                )}
                {is_archived && (
                  <Row>
                    <DeletedH2 data-testid="partner-archived">
                      ARCHIVED
                    </DeletedH2>
                  </Row>
                )}
              </Grid>
            )}

            {/* Some information + Insurance */}
            {partnerId && (
              <Grid item mobile={12}>
                <Grid container spacing="0.5rem">
                  <Grid item mobile={12} tablet="auto">
                    <AvatarInfosCard partnerData={partner.data} />
                  </Grid>
                  <Grid item mobile={12} tablet="auto">
                    <InsuranceState
                      secursusEnabled={partner.data.secursus_enabled}
                    />
                  </Grid>
                  {isPartnerIdCardNotCompleted(loading, values) && (
                    <Grid item mobile={12} tablet="auto">
                      <Alert
                        data-testid="missing-info-alert"
                        showIcon
                        type="warning"
                        message=" Missing information required"
                        description={
                          <ul>
                            {!values.partner_id_card?.client_name?.trim() && (
                              <li>Name</li>
                            )}
                            {!values.partner_id_card?.country_code?.trim() && (
                              <li>Country code</li>
                            )}
                            {!values.phone_number?.trim() && (
                              <li>Phone number</li>
                            )}
                            {!mainAddress?.length && <li>Main address </li>}
                          </ul>
                        }
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
            {/* field to associate LP Account to partner */}
            {!partnerId && (
              <Card elevation={0} sx={{ borderRadius: '10px' }}>
                <Text
                  variant="titleL"
                  sx={{
                    marginLeft: '1rem',
                    marginTop: '1rem',
                  }}
                >
                  Account (enter valid email address)
                </Text>
                <CardContent>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    gap="1rem"
                    alignItems="center"
                    width="50%"
                  >
                    <TextField
                      onChange={handlepAccountInputChange}
                      label="Email LP Account "
                      placeholder="Email LPAccount"
                      name="emailLpAccount"
                      value={emailLpAccount}
                      data-testid="email-lp-account"
                    />
                    <PillButton
                      data-testid="lp-account-btn"
                      onClick={async () => {
                        const { success, error, id } =
                          await getSingleAccount(emailLpAccount);

                        if (error) {
                          const msg =
                            error.response.status === 400
                              ? 'LP account must be a valid email'
                              : 'LP account not found';

                          addError(msg);
                        }
                        if (success) {
                          setFieldValue('lp_account_id', id);
                          addMessage(
                            {
                              msg: 'LP Account associated',
                              type: MessageType.Success,
                            },
                            'root',
                            {}
                          );
                        }
                      }}
                    >
                      Find
                    </PillButton>
                  </Stack>
                </CardContent>
              </Card>
            )}
            {/* Partner ID Card + Other Information */}
            <Grid item mobile={12}>
              <Grid container spacing="0.5rem">
                <Grid item mobile={12} laptop={6}>
                  <Stack spacing="0.5rem">
                    <PartnerIdCard
                      erpSynchronizedAt={
                        partner.data.partner_id_card.erp_synchronized_at
                      }
                    />
                    <ContractInfosCard />
                  </Stack>
                </Grid>
                <Grid item mobile={12} laptop={6}>
                  <Grid container spacing="0.5rem">
                    <Grid item mobile={12}>
                      <OtherInformation />
                    </Grid>
                    <Grid item mobile={12}>
                      <AccountManagement />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* Reset/Submit form buttons  */}
            <Grid item alignSelf="flex-end">
              <EditButtonsOnForm
                handleCancel={resetForm}
                handleSaveEdit={submitForm}
                disabledCancel={isEqual(initialValues, values)}
                disabledSubmit={
                  !isValid || isSubmitting || isEqual(initialValues, values)
                }
                requiredScopes={[scopes.EDIT_LPVILLAGE_PARTNER]}
              />
            </Grid>
            {/* Tabs */}
            {partnerId && (
              <Grid item mobile={12} sx={{ maxWidth: '100% !important' }}>
                <Tabs partner={partner} updatePartner={updatePartner} />
              </Grid>
            )}
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default LPPartnerDetailsContent;
