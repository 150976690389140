import { useContext } from 'react';

import { useMutation } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { syncErrors } from './Errors';
import { PartnerData } from './useLPPartner';
import { CurrentPartnerContext } from '../views/LPPartnerDetails';
import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import useToasts from '@/shared/hooks/useToasts';
import { MessageType } from '@/shared/hooks/useToasts.types';

export const syncContract = async ({
  partnershipId,
  makeRequest,
}: {
  partnershipId?: string;
  makeRequest: any;
}): Promise<void> => {
  const { data, error } = await makeRequest({
    path: `/partners/${partnershipId}/sync-erp`,
    method: 'post',
  });

  if (!data || error) {
    const matchingError = syncErrors.find((syncError) =>
      error.response.data.message.includes(syncError.code)
    );

    if (matchingError) {
      throw new matchingError();
    }

    throw new Error('Failed to synchronize with the ERP');
  }
};

type UseSyncWithERPOptions = {
  partnershipId?: string;
};

export const useSyncWithERP = ({
  partnershipId,
}: UseSyncWithERPOptions = {}) => {
  const { id: partnerId } = useParams<{ id: string }>();

  const addError = useError();
  const addSuccess = useToasts((state) => state.addItem);

  let id = partnershipId;
  if (!id) {
    id = partnerId;
  }

  const { refetchPartner } = useContext<PartnerData>(CurrentPartnerContext);

  const [, makeRequest] = useImperativeRequestWrapper('lpVillageV3');

  return useMutation({
    async onSuccess(_data) {
      // Refresh the partner data
      await refetchPartner();

      addSuccess(
        {
          msg: 'Successfully sync partner info with ERP',
          type: MessageType.Success,
        },
        'root'
      );
    },
    async onError(error) {
      addError(error);
    },
    mutationFn: () => syncContract({ partnershipId: id, makeRequest }),
  });
};
