import React, { useMemo } from 'react';

import { Grid } from '@lp/ds-next';
import isEmpty from 'lodash/isEmpty';

import ContributionsTable from './ContributionsTable';
import useContributionsStore from '../../hooks/saContributions/useContributionsStore';

const SAContributionsTable = () => {
  const searchTerms = useContributionsStore((state) => state.searchTerms);
  const searchActive = useMemo(
    () => !Object.values(searchTerms).every(isEmpty),
    [searchTerms]
  );

  return (
    <Grid container justifyContent="center" spacing={3}>
      <Grid item mobile={11}>
        <ContributionsTable
          store={useContributionsStore}
          showSizeChanger={true}
          hasFilters={!searchActive}
          hasSearch={true}
          endpoint={`/admin/contributions${searchActive ? '/search' : ''}`}
          searchActive={searchActive}
        />
      </Grid>
    </Grid>
  );
};

export default SAContributionsTable;
