import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
	.ant-typography .ant-typography-copy {
		color: #38a169;
			:hover {
				color: #38a169;
			}
	}
  body {
		margin: 0;
		padding: 0;
    background: ${(props) => props.theme.colors.adminPanel.grey}; 
		font-family: TTNormsPro-Regular;
		width: 100%;
		max-width: 100%;
	}

	a {
		color: black;
		text-decoration: none;
    transition: color 0.2s linear;
		&:hover {
			color: ${(props) => props.theme.colors.adminPanel.darkgreen};
		}
	}

  .returnRow {
    background: rgba(48, 181, 120, 0.3);
	}
	
	.newRecord {
	    background: rgba(255, 189, 48, 0.3);
	}
	
	.defaultActiveOffer {
	    background: rgba(48, 181, 120, 0.3);
	}
	
	.ant-image-preview-img {
	    display: inline;
	}
`;

export default GlobalStyle;
