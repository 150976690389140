import React, { useEffect, useState } from 'react';

import { EyeOutlined } from '@ant-design/icons';
import { Box, Grid } from '@lp/ds-next';
import { useNavigate } from 'react-router-dom';

import useTransaction, {
  TRANSACTION_OBJECT_LABEL,
  TRANSACTION_STATE_LABEL,
} from '../hooks/useTransaction';
import PageTitle from '@/components/layout/PageTitle';
import List from '@/componentv2/List/List';

const TransactionList = () => {
  const { getListTransaction } = useTransaction();
  const navigate = useNavigate();

  const [transactions, setTransactions] = useState<any>();

  const columns = [
    {
      header: 'Transaction ID',
      accessor: 'id',
      cell: (info) => info.getValue(),
      isSelectable: true,
    },
    { header: 'Created At', accessor: 'created_at', dataType: 'date' },
    { header: 'Updated At', accessor: 'updated_at', dataType: 'date' },
    { header: 'Paid object type', accessor: 'paidObjectId' },
    {
      header: 'Transaction Method Name',
      accessor: 'transaction_object_type',
      cell: (info) => {
        if (info.getValue()) {
          return TRANSACTION_OBJECT_LABEL[info.getValue()];
        }

        return TRANSACTION_OBJECT_LABEL.TRANSACTION_OBJECT_TYPE_UNSPECIFIED;
      },
    },
    {
      header: 'State',
      accessor: 'state',
      cell: (info) => TRANSACTION_STATE_LABEL[info.getValue()],
    },
    { header: 'Amount', accessor: 'amount' },
    {
      header: '',
      accessor: '-',
      cell: (info) => (
        <>
          <Box display="flex">
            <EyeOutlined
              style={{ fontSize: '24px' }}
              onClick={() => {
                navigate(`${info.row.original.id}`);
              }}
            />
          </Box>
        </>
      ),
    },
  ];

  useEffect(() => {
    const loadtData = async () => {
      const data = await getListTransaction();
      setTransactions(data);
    };

    loadtData();
  }, [getListTransaction, setTransactions]);

  return (
    <>
      <Grid container justifyContent="center" spacing={3}>
        <Grid item mobile={11}>
          <PageTitle title="Transactions" />
        </Grid>
        <Grid item style={{ background: '#E6E6E6' }}>
          {transactions?.transactions && (
            <List data={transactions.transactions} columns={columns} />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default TransactionList;
