import { useMemo } from 'react';

import isEmpty from 'lodash/isEmpty';

import useLPUsersStore from './useLPUsersStore';
import lpAccountPostProcessor from '../helpers/lpAccountPostProcessor';
import usePaginatedTableData from '@/hooks/usePaginatedTableData';

export interface IComposeAccountsUrl {
  id: string | undefined;
  domain: 'shops' | 'partners';
}
const useGetAccounts = (params?: IComposeAccountsUrl) => {
  const dispatch = useLPUsersStore((state) => state.dispatch);
  const pageSize = useLPUsersStore((state) => state.pageSize);
  const offset = useLPUsersStore((state) => state.offset);
  const sortBy = useLPUsersStore((state) => state.sortBy);
  const order = useLPUsersStore((state) => state.order);
  const searchTerms = useLPUsersStore((state) => state.searchTerms);
  const filters = useLPUsersStore((state) => state.filters);

  const searchActive = useMemo(
    () => !Object.values(searchTerms).every(isEmpty),
    [searchTerms]
  );
  const queryParams = useMemo(
    () => (searchActive ? searchTerms : filters),
    [searchActive, searchTerms, filters]
  );

  const path = params
    ? `/admin/${params.domain}/${params.id}/accounts`
    : searchActive
      ? '/admin/accounts/search'
      : '/admin/accounts';

  usePaginatedTableData({
    params: {
      path: path,
      method: 'get',
      reducer: lpAccountPostProcessor,
    },
    dispatch,
    queryParams: Object.assign(
      {
        pageSize,
        offset,
        sortBy,
        order,
        ...queryParams,
      },
      searchActive ? searchTerms : {}
    ),
    queryParamsOptions: {
      skipEmptyString: true,
      skipNull: true,
    },
    service: params?.id ? 'lpVillage' : 'serviceLpAccount',
  });

  if (!params || (!params.domain && !params.id)) return;
};

export default useGetAccounts;
