import React from 'react';

import { Stack } from '@lp/ds-next';

import NewsletterTable from '../NewsletterTable';
import SocialMediaTable from '../SocialMediaTable';

const DashboardView = () => (
  <Stack gap="2.5rem" pl="2rem">
    <SocialMediaTable />
    <NewsletterTable />
  </Stack>
);

export default DashboardView;
