import React from 'react';

import styled from 'styled-components';
const SVG = styled.svg`
  cursor: pointer;
`;

const ERPPushedIcon = (props) => (
  <SVG width="22" height="22" {...props} viewBox="0 0 22 22">
    <path
      d="M7.0557 8.66667L1.0557 8.66667M6.72237 4.33334L4.0557 6.66667M4.0557 6.66667L1.38904 4.33334M4.0557 6.66667L4.0557 1"
      stroke="#00BF6F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.97436 21.8678H11.2672H20.4102V17.821H18.7615C18.6416 16.7418 17.9121 15.5228 17.5624 15.0481L18.7615 13.8491L16.5882 11.6757L15.3891 12.8748C14.4898 12.2153 13.2157 11.9505 12.6911 11.9006V10.1769H9.54356V11.9006C8.52434 11.7807 7.32026 12.5001 6.84563 12.8748L5.72149 11.7507L3.47321 13.9989L4.67229 15.198C3.83293 16.0374 3.62309 17.2964 3.62309 17.821H1.97436V21.8678Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.56914 9.20277H13.6652V11.085C14.1382 11.1981 14.6995 11.3775 15.2378 11.6482L16.588 10.2981L20.1391 13.8492L18.8062 15.1821C18.9127 15.3666 19.0215 15.5695 19.1252 15.7842C19.2775 16.0994 19.4299 16.4624 19.5462 16.8468H21.3843V21.8679H18.7613V19.9194H19.4358V18.7953H17.8893L17.793 17.9287C17.7504 17.5451 17.5893 17.0842 17.3707 16.6318C17.1561 16.1875 16.9175 15.8156 16.7779 15.6262L16.281 14.9517L17.3835 13.8492L16.588 13.0536L15.4868 14.1548L14.8128 13.6606C14.1145 13.1485 13.0399 12.9126 12.5986 12.8705L11.7167 12.7865V11.1513H10.5176V12.7904L9.6316 12.8709C8.96079 12.9319 8.00215 13.2545 7.40416 13.6731L6.73453 14.1418L5.72132 13.1286L4.85085 13.999L6.04993 15.1981L5.36103 15.887C4.77542 16.4726 4.59718 17.4332 4.59718 17.8211V18.7953H2.94845V19.9194H3.62293V21.8679H0.999939V16.8468H2.7655C2.87074 16.3662 3.05944 15.8096 3.37927 15.2831L2.09524 13.999L5.72132 10.373L7.00306 11.6547C7.50085 11.4062 8.04786 11.2109 8.56914 11.0833V9.20277Z"
      fill="#00BF6F"
    />
    <path
      d="M5.87171 21.6435V16.5473H8.9449V18.0034H7.26133V18.513H8.51732V19.6778H7.26133V20.1875H8.99834V21.6435H5.87171Z"
      fill="#00BF6F"
    />
    <path
      d="M9.58793 21.6435V16.5473H11.512C12.4206 16.5473 13.0085 17.3481 13.0085 18.4038C13.0085 19.4594 12.4206 19.8962 12.4206 19.8962L13.1421 21.6435H11.6991L11.2181 20.2603H10.9775V21.6435H9.58793ZM10.9775 18.8042H11.2982C11.512 18.8042 11.6189 18.6222 11.6189 18.4038C11.6189 18.1854 11.512 18.0034 11.2982 18.0034H10.9775V18.8042Z"
      fill="#00BF6F"
    />
    <path
      d="M13.5964 21.6435V16.5473H15.4403C16.3757 16.5473 16.9636 17.3481 16.9636 18.4402C16.9636 19.5322 16.3757 20.3331 15.4403 20.3331H14.986V21.6435H13.5964ZM14.986 18.877H15.1998C15.4403 18.877 15.574 18.695 15.574 18.4402C15.574 18.1854 15.4403 18.0034 15.1998 18.0034H14.986V18.877Z"
      fill="#00BF6F"
    />
  </SVG>
);

export default ERPPushedIcon;
