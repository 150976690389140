import React from 'react';

import {
  ChakraProvider,
  extendTheme,
  withDefaultColorScheme,
} from '@chakra-ui/react';
import { ThemeProvider } from '@livingpackets/design-system-react';
import { ThemeProvider as DSThemeProvider } from '@lp/ds-next';
import * as Sentry from '@sentry/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ConfigProvider } from 'antd';
import ReactGA from 'react-ga';
import { Routes, Route } from 'react-router-dom';

import AppState from './AppState';
import GlobalStyle from './GlobalStyle';
import MainRouter from './MainRouter';
import { tracking } from '../config';
import { useTracking } from '../helpers/tracking';
import useAppState from '../hooks/useAppState';
import { queryClient } from '../lib/react-query';
import AppLoading from '../views/AppLoading';
import Login from '../views/Login';
import NotFound from '../views/NotFound';
import { appRoutes } from '@/configs/appRoutes';
import { useAuth0 } from '@/shared/auth0';
import MessageHub from '@/shared/features/message-hub/MessageHub';

ReactGA.initialize(tracking.GOOGLE_TRACKING_ID, {
  testMode: !tracking.TRACKING_ENABLED,
  titleCase: false,
});

if (tracking.TRACKING_ENABLED || tracking.DEBUG_SENTRY) {
  Sentry.init({
    dsn: tracking.SENTRY_DSN,
    debug: tracking.DEBUG_SENTRY,
    environment: tracking.DEBUG_SENTRY ? 'test' : 'production',
  });
}

const chakraTheme = extendTheme(
  {
    components: {
      Button: {
        baseStyle: {
          borderRadius: '16px',
        },
      },
    },
  },
  withDefaultColorScheme({ colorScheme: 'green' })
);

const App = () => {
  const { loading: auth0Loading } = useAuth0();
  const appStateLoading = useAppState((state) => state.loading);

  useTracking();

  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#38a169',
          },
        }}
      >
        <ChakraProvider theme={chakraTheme}>
          <ThemeProvider>
            <DSThemeProvider>
              <GlobalStyle />
              <AppState>
                <MessageHub />
                {!auth0Loading && !appStateLoading ? (
                  <Routes>
                    <Route path={appRoutes.LOGIN} element={<Login />} />
                    <Route path={appRoutes.PAGE_404} element={<NotFound />} />
                    <Route path="*" element={<MainRouter />} />
                  </Routes>
                ) : (
                  <AppLoading />
                )}
              </AppState>
            </DSThemeProvider>
          </ThemeProvider>
        </ChakraProvider>
      </ConfigProvider>
    </QueryClientProvider>
  );
};

export default App;
