import React, { useState, useCallback, useEffect, useRef } from 'react';

import { Select, Col, Row } from 'antd';
import styled from 'styled-components';

import { useLifecycleStatuses } from '../api/lifecyle/getLifecycle';
import { useLifecycleSubStatuses } from '../api/lifecyle/getLifecycleSubstatus';

const { Option } = Select;

const StyledSelect = styled(Select)`
  width: 16rem;
  padding-bottom: 0.5rem;
`;

const LifeCycleTypeForm = ({
  setLifeCycleTypeCode,
  lastState,
}: {
  setLifeCycleTypeCode: (codeStatus: string, codeSubStatus: string) => void;
  lastState?: any;
}) => {
  const { data: lifecycleStatuses } = useLifecycleStatuses();
  const { data: lifecycleSubStatuses } = useLifecycleSubStatuses();

  const [statutSelected, setStatutSelected] = useState<string | undefined>(
    undefined
  );
  const [subStatutSelected, setSubStatutSelected] = useState('');
  const [subStatusList, setSubStatusList] = useState<string[]>([]);
  const [isSubstatusVisible, setIsSubstatusVisible] = useState(false);

  const statutSelectRef = useRef<any>(null);

  const clear = useCallback(() => {
    setSubStatusList([]);
    setSubStatutSelected(() => '');
    setLifeCycleTypeCode('', '');
  }, [setLifeCycleTypeCode]);

  const handleSelectStatus = useCallback(
    (val) => {
      clear();

      if (
        val &&
        lifecycleSubStatuses?.allowed_transitions[val] &&
        lifecycleSubStatuses?.allowed_transitions[val]?.sub_statuses?.length
      ) {
        setSubStatusList(
          lifecycleSubStatuses?.allowed_transitions[val]?.sub_statuses
        );
      }
      setStatutSelected(val);
      setLifeCycleTypeCode(val, '');
    },
    [clear, lifecycleSubStatuses, setLifeCycleTypeCode]
  );

  const handleSelectSubStatus = useCallback(
    (val) => {
      setSubStatutSelected(val);
      if (statutSelected) setLifeCycleTypeCode(statutSelected, val);
    },
    [setLifeCycleTypeCode, statutSelected, setSubStatutSelected]
  );

  useEffect(() => {
    if (lastState && lastState?.status && !statutSelected) {
      handleSelectStatus(lastState.status);
    }
    setIsSubstatusVisible(lastState?.status === statutSelected);
  }, [lastState, statutSelected, handleSelectStatus]);

  return (
    <>
      <Row>
        <Col>
          <StyledSelect
            data-testid="lifecyle-select-status"
            onChange={handleSelectStatus}
            placeholder="Select a status"
            ref={statutSelectRef}
            value={statutSelected}
            virtual={false}
          >
            {lifecycleStatuses?.statuses?.map((status) => (
              <Option
                key={status}
                value={status}
                data-testid={`lifecycle-status-${status}`}
              >
                {status}
              </Option>
            ))}
          </StyledSelect>
        </Col>
      </Row>

      {subStatusList.length !== 0 && isSubstatusVisible && (
        <Row>
          <Col>
            <StyledSelect
              data-testid="lifecyle-select-substatus"
              onChange={handleSelectSubStatus}
              placeholder="Select a sub status"
              value={subStatutSelected || undefined}
            >
              <Option value={undefined}>None</Option>
              {subStatusList.map((substatus) => (
                <Option
                  key={substatus}
                  value={substatus}
                  data-testid={`lifecycle-substatus-${substatus}`}
                >
                  {substatus}
                </Option>
              ))}
            </StyledSelect>
          </Col>
        </Row>
      )}
    </>
  );
};

export default LifeCycleTypeForm;
