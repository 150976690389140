import React from 'react';

import { withTheme } from 'styled-components';

import SVG from './SVG';

const BlockedIcon = (props) => (
  <SVG width="1rem" height="1rem" viewBox="0 0 16 16" {...props}>
    <g>
      <circle cx="8.00023" cy="8.00023" r="6.98851" fill="#FF3838" />
      <path
        d="M5.19418 4.56818C4.96275 4.35222 4.60007 4.36475 4.3841 4.59617C4.16813 4.8276 4.18067 5.19028 4.41209 5.40625L7.1864 7.99524L4.41209 10.5842C4.18067 10.8002 4.16813 11.1629 4.3841 11.3943C4.60007 11.6257 4.96275 11.6383 5.19418 11.4223L8.02647 8.7792L10.8588 11.4223C11.0902 11.6383 11.4529 11.6257 11.6688 11.3943C11.8848 11.1629 11.8723 10.8002 11.6409 10.5842L8.86654 7.99524L11.6409 5.40625C11.8723 5.19028 11.8848 4.8276 11.6688 4.59617C11.4529 4.36475 11.0902 4.35222 10.8588 4.56818L10.8288 4.59617L8.02647 7.21129L5.22417 4.59617L5.19418 4.56818Z"
        fill="white"
      />
    </g>
  </SVG>
);

export default withTheme(BlockedIcon);
