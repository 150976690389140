import React from 'react';

import { withTheme } from 'styled-components';

import SVG from './SVG';

const PendingV2Icon = (props) => (
  <SVG SVG width="1rem" height="1rem" viewBox="0 0 16 16" {...props}>
    <g>
      <path
        d="M10.9675 5.032C10.1485 4.213 9.07751 3.8 7.99951 3.8V8L5.03151 10.968C6.66951 12.606 9.32951 12.606 10.9745 10.968C12.6125 9.33 12.6125 6.67 10.9675 5.032ZM7.99951 1C4.13551 1 0.999512 4.136 0.999512 8C0.999512 11.864 4.13551 15 7.99951 15C11.8635 15 14.9995 11.864 14.9995 8C14.9995 4.136 11.8635 1 7.99951 1ZM7.99951 13.6C4.90551 13.6 2.39951 11.094 2.39951 8C2.39951 4.906 4.90551 2.4 7.99951 2.4C11.0935 2.4 13.5995 4.906 13.5995 8C13.5995 11.094 11.0935 13.6 7.99951 13.6Z"
        fill="#F8B400"
      />
    </g>
  </SVG>
);

export default withTheme(PendingV2Icon);
